var colorTheme = {}


colorTheme.light = {}
//colorTheme.light.primary = "#80ffff"
//colorTheme.light.background = "#ffffff"
//colorTheme.light.tertiary = "#a0a0a0"
//colorTheme.light.oneline = "#ffffff"
//colorTheme.light.card = "#ffafd8"
//colorTheme.light.cardtext = "#ff0000"
//colorTheme.light.cardicon = "#ff5000"
//colorTheme.light.onetooloverlay = "#ffafd8"
//colorTheme.light.onetool = "#ff9fd8"
colorTheme.light.mainheader = "#ffffff"
colorTheme.light.quicksearch = "#ffffff"
//colorTheme.light.primaryselected = "#303000"
//colorTheme.light.primarytext = "#0000ff"
//colorTheme.light.primaryicons = "#0050ff"

colorTheme.dark = {}
colorTheme.dark.primary = "#263238"
colorTheme.dark.secondary = "#FFC107"
colorTheme.dark.background = "#162226"
colorTheme.dark.tertiary = "#37474F"
colorTheme.dark.oneline = "#162226"
colorTheme.dark.card = "#090030"
colorTheme.dark.cardtext = "#ffffff"
colorTheme.dark.cardicon = "#ffffff"
colorTheme.dark.onetooloverlay = "#162226"
colorTheme.dark.onetool = "#0f0f28"
//colorTheme.dark.primaryselected = "#ffffff"
//colorTheme.dark.primarytext = "#ffffff"
//colorTheme.dark.primaryicons = "#9050ff"


colorTheme.styling = {}

colorTheme.styling.light = {}
colorTheme.styling.light.cardview = {
    widegroup: { header: "grey lighten-3 primary--text", background: "white", iconcolor: { expanded: "primary", collapsed: "primary", other: "primary" } },
    group: { header: "primary white--text", iconcolor: { expanded: "secondary", collapsed: "secondary", other: "white" } },
    tool: { header: "white--text", iconcolor: { expanded: "white", collapsed: "white", other: "white" } }
}
colorTheme.styling.dark = {}
colorTheme.styling.dark.cardview = {
    widegroup: { header: "indigo darken-4 white--text", background: "background", iconcolor: { expanded: "purple", collapsed: "indigo", other: "white" } },
    group: { header: "indigo darken-4 yellow--text", iconcolor: { expanded: "pink", collapsed: "secondary", other: "white" } },
    tool: { header: "yellow--text", iconcolor: { expanded: "green", collapsed: "pink", other: "yellow" } }
}

colorTheme.styling.light.tableview = { header: "grey lighten-4"}
colorTheme.styling.dark.tableview = { header: "grey darken-3"}

colorTheme.updateToTheme = function (incomingTheme,themename, values) {
    console.log("UPDATING THEME")
    if (incomingTheme.theme.themes[themename] === undefined) {
        incomingTheme.theme.themes[themename] = {}
    }
    var target = incomingTheme.theme.themes[themename]
    for (const [key, value] of Object.entries(values)) {
        target[key] = value
    }
}

colorTheme.update = function (incomingTheme) {
    console.log("uploading color theme")
    colorTheme.updateToTheme(incomingTheme,"light",colorTheme.light)
    colorTheme.updateToTheme(incomingTheme,"dark",colorTheme.dark)
    return incomingTheme
}

module.exports = colorTheme