<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown || useFullscreen"
    width="50vw"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon>
          <v-icon v-if="useFullscreen" color="white" @click="toggleFullscreen()"
            >mdi-arrow-collapse</v-icon
          >
          <v-icon v-else color="white" @click="toggleFullscreen()"
            >mdi-arrow-expand</v-icon
          >
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <template v-for="(item, index) in message">
          <v-subheader v-if="item.header" :key="index + '-sh'">
            {{ item.header }}
          </v-subheader>

          <v-divider v-if="item.divider" :key="index + '-dv'" :class="item.divclass">
          </v-divider>

          <span v-if="item.text" :key="index + '-tx'" :class="item.textclass">
            {{ item.text }}
          </span>
          <span v-if="item.textbr" :key="index + '-tb'" :class="item.textclass">
            {{ item.textbr }}<br />
          </span>

          <v-jsoneditor
            v-if="item.json"
            v-model="jsonInput"
            :height="jsonHeight"
            :key="index + '-js'"
            :options="jsonoptions"
          />
        </template>
      </v-card-text>
      <v-card-actions class="pt-0">
        <template v-if="showAccept">
          <v-btn color="primary darken-1" text @click.native="agree">{{
            options.accept
          }}</v-btn>
        </template>
        <v-spacer></v-spacer>
        <template v-if="showReject">
          <v-btn color="grey" text @click.native="cancel">{{ options.reject }}</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
import VJsoneditor from "v-jsoneditor/src/index";
import dataloader from "@/api/dataloader.js";

export default {
  components: {
    VJsoneditor,
  },
  data: () => ({
    useFullscreen: false,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      zIndex: 200,
      accept: "Yes",
      reject: "Cancel",
    },
    jsonInput: {
      hello: "nothing to see here",
    },
    ToolID: "",
    jsonoptions: { mode: "code" },
  }),
  methods: {
    open(title, jsonIn, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      this.jsonInput = JSON.parse(JSON.stringify(jsonIn));
      // save the tool ID but don't show in the code (to avoid accidental changes)
      this.ToolID = this.jsonInput._id;
      delete this.jsonInput._id;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.dialog = false;
      this.saveTool();
      this.resolve({ accept: true });
    },
    cancel() {
      this.dialog = false;
      this.resolve({ accept: false });
    },
    showAccept() {
      return this.options.accept.length > 0;
    },
    showReject() {
      return this.options.reject.length > 0;
    },
    toggleFullscreen() {
      this.useFullscreen = !this.useFullscreen;
    },
    saveTool() {
      if (this.ToolID === "") return;
      var toolDataToSave = JSON.parse(JSON.stringify(this.jsonInput));
      toolDataToSave._id = this.ToolID;
      dataloader.sendToolReplacement(toolDataToSave);
      this.$store.commit("tools/updateSingleTool", toolDataToSave);
    },
  },
  computed: {
    jsonHeight() {
      if (this.useFullscreen) return "80vh";
      else return "70vh";
    },
  },
};
</script>

<style>
div.jsoneditor-menu {
  background-color: #455a64;
  border: thin solid #263238;
}
div.jsoneditor {
  border: thin solid #263238;
}
a.jsoneditor-poweredBy {
  color: #eceff1;
}
</style>
