import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('@/views/MainPage')
        },
        {
            path: '/n',
            component: () => import('@/views/MainPage')
        },
        {
            path: '/t',
            component: () => import('@/views/MainPage')
        },
        {
            path: '/n/view/:sublist',
            component: () => import('@/views/ViewPage')
        },
        {
            path: '/n/view/:sublist/:option',
            component: () => import('@/views/ViewPage')
        },
        {
            path: '/n/edit',
            component: () => import('@/views/ObjectEditor')
        },
        {
            path: '/n/editviews',
            component: () => import('@/views/ViewStructureEditor')
        },
        {
            path: '/n/upload',
            component: () => import('@/views/BulkUpload')
        },
        {
            path: '/n/edit/images',
            component: () => import('@/views/ImageEditor')
        },
        {
            path: '/n/add/image',
            component: () => import('@/views/UploadImage')
        },
        {
            path: '/login',
            component: () => import('@/views/LoginPage')
        },
        {
            path: '/n/login',
            component: () => import('@/views/LoginPage')
        },
        {
            path: '/n/theme',
            component: () => import('@/views/ColorEditor')
        },
        /*****************************/
    ],
});

export default router;