/****
 * 
 * Functions to perform the group/tool/template splitting, data search/filtering, and inheritance/derivatives
 * 
 */

var merge = require('lodash.merge');
var uniq = require('lodash.uniq');
const moment = require("moment");

var ProcessToolData = {}

/**
 * Pick a tool object, based on toProcess.Name, from the source {name:entry} and merge it into the target. 
 * @param {object} toProcess - the tool object to process inheritance on. This field only requires toProcess.Name
 * @param {object} source - a {name:entry} set of tools from which to pick data
 * @param {object} target - the target {name:entry} object into which to merge the data.  if it already exists, then don't merge it (as we already visited it)
 * 
 * toProcess is used to make it easy to use either full tool objects or simple {Name:value} objects, since only
 * the toProcess.Name field is used.
 * 
 * For the toProcess.Name entry, find the tool in the source list, and copy it over. If .Inherits is not specified,
 * then it simply amounts to deep cloning the source to target entry.  If .Inherits is a list, then work through it
 * and pick each item and process inheritance. Do so by mergin a copy of the tool object into the target, and at
 * the very end add the actual target. 
 * 
 * This means the lowest priority is the 1st entry of the Inherits list. The actual tool object always overrides
 * the inherited data.
 * 
 * for exmaple, if tools A:{X=2,Y:1}, B:{Y=5}, C:{X=8, Inherits:["A","B"]}     
 * and C inherits from A & B, then C will become {X=8,Y=5} because A's entries were overridden
 * when B and the finally C were added.
 * 
 * Because data is cloned, inheritance should only be 1 level deep. Tools should not inherit from other objects
 * that themselves inherit. Because of this, the "template" type makes it easy to declare something that gets
 * inherited from, and itself therefore should not contain "Inherits" statements.  Rather than nested inheritance,
 * the Inherits list of a tool should contain the nesting (e.g.  Inherits=[A,B,C] rather than C, where C inherits from B, and B inherits from A) 
 */
var unitInheritance = function (toProcess, source, target) {
    // if target contains object already, then we already visited for inheritance and nothing to do    
    if (target[toProcess.Name] !== undefined) return;
    // otherwise, pick object from source and check for inheritance
    const InheritList = source[toProcess.Name].Inherits
    if (InheritList === undefined) {
        target[toProcess.Name] = JSON.parse(JSON.stringify(source[toProcess.Name]))
        return
    }
    // do a deep merge so that sub-properties are not lost
    // run inheritance on all items to be merged first (as they may have inheritance too)
    var newEntry = {}
    for (const inheritFrom of InheritList) {
        if (source[inheritFrom] !== undefined) {
            unitInheritance(source[inheritFrom], source, target)
            merge(newEntry, target[inheritFrom])
        }
    }
    merge(newEntry, JSON.parse(JSON.stringify(source[toProcess.Name])))
    target[toProcess.Name] = newEntry
}

/**
 * Process the tools in the toProcess object {name:entry}, to look them upin toolset, and inherit from the overall toolset 
 * @param {*} toProcess - the objecyt {name:entry} list of tools to process inheritance on
 * @param {*} toolset - to complete original toolset (to pull inheritance from)
 * @returns {object} - a new object cloned from toProcess with clones of anything inherited from the toolset
 * 
 * Utilizes unitInheritance, to process each entry.
 * the toProcess entries can be "short", ie. { name:{Name:name}, ...} as only the .Name field is used
 * it's done that way because most of the processing will be on the full toolset, which is stored
 * as an object of that type, ie by calling processInheritance(toolset,toolset)
 */
ProcessToolData.processInheritance = function (toProcess, toolset) {
    var newToolset = {}
    for (const [, entry] of Object.entries(toProcess)) {
        unitInheritance(entry, toolset, newToolset)
    }
    return newToolset
}

/**
 * Look up a parameter inside an object, using "." to descend into any hierarcy 
 * @param {object} data - the data object to get the sourcename from 
 * @param {string} sourcename - the name of the source to find. Use "." to descend into nested objects
 * @returns {*} - the data[sourcename] entry, or undefined if it does not exist
 * For example, if data = {a:{b:{c:5}}}  then "a.b.c" would return 5, while "a.b" would return {c:5} 
 */
var getDataValue = function (data, sourcename) {
    var value = data
    if (value === undefined) return undefined
    for (const nextStep of sourcename.split(".")) {
        if (value[nextStep] === undefined) return undefined
        value = value[nextStep]
    }
    return value
}

/**
 * Modify a value inside an object or nested object (see {@link getDataValue}), creating the hierarchy if needed
 * @param {object} data - the data object to modify
 * @param {string} targetname - the name of the target parameter to modify. Use "." to descend into nested objects
 * @param {*} value - the new value to set
 * @returns {boolean} - true if successful, false if data was undefined
 * 
 * Descends into the object following targetname, modifying the data at the final node. If target does not exist
 * then create it.  For example, data={a:{b:{c:5}}} and targetname="a.b.c", value=6 then new data={a:{b:{c:6}}}. 
 * If targetname="a.d.e" and value=6, then new data={a:{ b:{c:5},d:{e:6} }}
 */
var setDataValue = function (data, targetname, value) {
    if (data === undefined) return false
    var addr = data
    var addrList = targetname.split(".")
    for (var k = 0; k < addrList.length; k++) {
        if (addr[addrList[k]] === undefined) addr[addrList[k]] = {}
        if (k == addrList.length - 1) {
            addr[addrList[k]] = value
        } else
            addr = addr[addrList[k]]
    }
    return true
}


/**
 * Go through the tools in childrenToProcess and, for all non-hidden entries, pick out the sourceNameList.Elements
 * and add them to the resulting array.  
 * @param {object} childrenToProcess - an object of tool entries {name:entry} 
 * @param {array} sourceNameList - a list of source addresses [ {Element:source}, ...] that address the data to collect
 * @returns {array} - an array of all the tool entries and their source values
 * 
 * This function can be used to aggregate specific data common across all tools (e.g. certain features, categories, etc)
 */
var collectData = function (childrenToProcess, sourceNameList) {
    // get the derivative data in this object        
    var result = []
    for (const [, entry] of Object.entries(childrenToProcess)) {
        // go down the hierarchy        
        if (entry._hidden !== true) {
            if (entry._children !== undefined)
                result = [...result, ...collectData(entry._children, sourceNameList)]
            // and pick out the elements        
            for (const sourcename of sourceNameList.Elements) {
                const addToData = getDataValue(entry, sourcename)
                if (addToData !== undefined) result.push(addToData)
            }
        }
    }
    return result
}

/**
 * Helper function for {@link applyFunctionTo}.
 * @param {array} data - An array of input elements, should be in text form
 * @returns {string} - A comma separated string of the unique elements in data
 */
var dataFunctionCommaList = function (data) {
    return (uniq(data)).join(', ')
}

/**
 * Take an array return the unique value, or multiple (qty) if not unique
 * @param {array} data - An array of input elements 
 * @returns {string} - If data.length is zero, then "Nothing"; if data.length=1 then data[0] entry; otherwise, "Multiple (count)"
 */
var dataFunctionOneOrVarious = function (data) {
    const uniqueData = uniq(data)
    if (uniqueData.length == 0) return "Nothing"
    if (uniqueData.length == 1) return uniqueData[0]
    return "Multiple (" + uniqueData.length.toString() + ")"
}

/**
 * Create an object of all the entries merged together into one
 * @param {array} data - an array of objects 
 * @returns {object} - a single objects with the entries from data merged in one by one
 * 
 * The order of the object in the array matters, because entries are sequentially added to the result object.
 * So the last entry can overwrite any previous ones, if parameter keys in objects overlap.
 */
var dataFunctionObject = function (data) {
    var result = {}
    for (const entry of data) {
        Object.assign(result, entry)
    }
    return result
}

/**
 * Converts an array, for display or processing in the GUI. Used to aggregate complex structures
 * for example lists of model numbers or an array of staff names.
 * @param {*} data - the item to convert into text
 * @param {*} functionToDo - the function name: commalist, object, oneorvarious
 * @returns {string} - the text result of the function 
 * 
 * Commalist takes an array, removes duplicates, and returns a string with array entries comma separated
 * Oneorvarious takes an array, removes duplicates, and returns one of three choices:  Nothing, [single unique entry], Multiple (count)
 * this can be used to show model numbers but avoid long lists.
 * Object takes an array of objects and merges them into one single object to return. 
 * 
 */
var applyFunctionTo = function (data, functionToDo) {
    if (functionToDo === 'commalist')
        return dataFunctionCommaList(data)
    if (functionToDo === 'object')
        return dataFunctionObject(data)
    if (functionToDo === 'oneorvarious')
        return dataFunctionOneOrVarious(data)
    return "unknown function " + functionToDo
}


/*var a = {
    "_hidden": false,
    "_expanded": false, "_inherit": "TestRIE-F-Samco-230iP",
    "_children": {}, "_id": "619131c6bb011fbbb5e7c7b5", "Name": "TestRIE-F-Samco-230iP", "Label": "New RIE", "Type": "Tool", "Coral": "RIE-F-Samco-230iP",
    "Specs": { "Model": "SAMCO 230iP", "Location": "6U", "Staff": { "Primary": { "Donal Jamieson": true }, "Backup": { "Robert Bicchieri": true } }, "Fees": { "MIT": "7/wafer + 1/um" }, "Category": { "Etch": { "RIE": true } } }, "Details": { "OneLine": "Fluorine based etcher for silicon dielectrics", "Summary": "The SAMCO is an ICP-RIE that etches silicon based dielectrics (SiO, SiN, SiC) using fluorine chemistries.  The substrate can be heated up ???. The high density plasma is achieved by a separate plasma source and enables high etch rates. Low chamber pressure (< 5 mTorr) and variable chuck bias can enable vertical profiles and profile control. ", "Narrative": "The SAMCO is an ICP-RIE that etches silicon based dielectrics (SiO, SiN, SiC) using fluorine chemistries.  The substrate can be heated up ???. The high density plasma is achieved by a separate plasma source and enables high etch rates. Low chamber pressure (< 5 mTorr) and variable chuck bias can enable vertical profiles and profile control. ", "BestFor": "Dry etching with high aspect-ratio profiles and smooth sidewalls", "Limitations": "Restricted to etching of materials that form volatile fluorine compounds, e.g. silicon dielectrics. Not for sputtering of materials.", "CautionWith": "", "Alternatives": { "ICL / LAM590-TRL": { "name": "similar" } } }, "Image": "filenameoverride", "Inherits": ["RIE-F-Samco-230iP"],
    "Derives": { "Materials-Capability": { "Elements": ["Materials.Accepted"], "Function": "OR" }, "Specs.Gases": true },
    "Contents": [], "_ctr": 38, "_searchcount": 1
}
*/


/**
 * Go through the a tool's Derivces hierarchy and collect the data for each process each of the derived elements
 * @param {object} toProcess - 
 * 
 * The .Derives contains {target: {Element:source, Function:name}}.  The Element defines the source data from the
 * toProcess tool, which is run through the Function filter, and stored in toProcess[target].  The _dervice_data 
 * array keeps a record of which target points have been modified. 
 */
var unitDerivative = function (toProcess) {
    if (toProcess.Derives === undefined) return
    for (const [target, entry] of Object.entries(toProcess.Derives)) {
        var result = collectData(toProcess._children, entry)
        for (const functionToDo of entry.Function) {
            result = applyFunctionTo(result, functionToDo.toLowerCase())
        }
        setDataValue(toProcess, target, result)
        if (toProcess._derived_data === undefined) toProcess._derived_data = []
        toProcess._derived_data.push(target)
    }
    toProcess._derived = true
}

/**
 * Derive data for a set of tools, based on their Derives instruction. Results are modified directly in the toProcess entry
 * 
 * @param {object} toProcess - an object of {toolname:entry} to go through and derive parametes for each, modifying the objects
 * 
 * The .Derives entry of a tool contains {target: {Element:source, Function:name}}.  The Element defines the source data from the
 * toProcess tool, which is run through the Function filter, and stored in toProcess[target].  The _dervice_data 
 * array keeps a record of which target points have been modified. 
*/
ProcessToolData.processDerivatives = function (toProcess) {
    for (const [, entry] of Object.entries(toProcess)) {
        // derive the contents first, then the main item
        if ((entry._children !== undefined) && (entry._derived !== true) && (entry._hidden !== true))
            this.processDerivatives(entry._children)
        unitDerivative(entry)
    }
}

/**
 * Look up data[varName] using default values in case they are undefined
 * @param {object} data - the data object to pick the entry from 
 * @param {string} varName - the parameter name to look up in data 
 * @param {*} defaultValue - default value if lookup fails
 * @returns {*} - returns data[varName]. if data or data[varName] are undefined, return defaulValue 
 */
var getValueFor = function (data, varName, defaultValue) {
    if (data === undefined) return defaultValue
    if (data[varName] === undefined) return defaultValue
    return data[varName]
}

/**
 * Find all of the tools in toAdd that match a specific Type, and reconstruct them from alltools 
 * @param {array} toAdd - An array of tools. Only the {Name:value,Type:value,Contents:[]} is used
 * @param {array} isType - An array of type names (e.g. ['Tool','Build'] ) 
 * @param {object} alltools - the full tool list, used to construct the result from
 * @returns {array} - An array of tools picked from alltools that match the types in the toAdd array
 * 
 * The toAdd entries don't have to be the full tool objects. Only the Name, Type, and Contents are used
 * This allows us to reconstruct views (where we may not have the full tooldata) back into full tool objects.
 * The entry's contents are also parsed, and added to the results. Entries are only added once, so no
 * duplicates will exist (important for situations where different groups may contain overlapping tool sets)  
 */
var getAllOfTypes = function (toAdd, isType, alltools) {
    var result = []  // the result array
    var resultNames = []  // array of toolnames to easily keep track of what we added
    for (const entry of toAdd) {
        var toolEntry = alltools[entry]
        if (toolEntry !== undefined) {
            if (isType.includes(toolEntry.Type)) {
                if (!resultNames.includes(toolEntry.Name)) {
                    result.push(toolEntry)
                    resultNames.push(toolEntry.Name)
                }
            }
            if (toolEntry.Contents !== undefined) {
                const addToResults = getAllOfTypes(toolEntry.Contents, isType, alltools)
                if (addToResults.length > 0) {
                    // add results unless already there
                    for (const toAdd of addToResults) {
                        if (!resultNames.includes(toAdd.Name)) {
                            result.push(toAdd)
                            resultNames.push(toAdd.Name)
                        }
                    }
                }
            }
        }
    }
    return result
}

/**
 * Pre-processing filter for data
 * @param {*} dataIn - Incoming data
 * @param {*} cmdName - Name of the command to process (case sensitive)
 * @returns {*} - Outgoing result, or dataIn if cmdName not defined
 * 
 * Currently only "calcage" is defined.
 * calcage - assumes dataIn is a moment compatible date, calculate the time difference to now and return in # years
 * used to show age of equipment
 */
var preprocessData = function (dataIn, cmdName) {
    if (cmdName == 'calcage') {        
        var dataOut = moment().diff(moment(dataIn,["MM/DD/YYYY","YYYY-MM-DD"]), 'years', true)
        return dataOut
    }
    return dataIn
}

var unitBuild = function (toProcess, useTools, alltools) {
    var build = toProcess.Build
    if (build === undefined) {
        return []
    }
    // evaluate the search fields

    // plus any boolean commands inside (usually it's one or the other).
    // all boolean commands are shorthands for the qty command
    // ( and: gte=#searches,  or: gte=1,  xor: gte=1,lte=1 for 2 searches )
    // not is handled differently
    // qty --> count occurrances and use range...   qty:{gte:#, lte:#, {search} }

    // process base    
    if (build.base !== undefined) {
        var toadd
        if (build.base.useAll !== undefined) {
            toadd = Object.keys(alltools).filter(el => (el !== toProcess.Name));
        } else {
            toadd = getValueFor(build.base, 'include', Object.keys(alltools))
        }
        const istype = getValueFor(build.base, 'istype', ['Tool'])
        useTools = getAllOfTypes(toadd, istype, alltools)
    }

    // and/or/qty collection           
    var entries = undefined
    if (build.or !== undefined) entries = build.or
    if (build.and !== undefined) entries = build.and
    if (build.qty !== undefined) entries = build.qty

    if (entries !== undefined) {
        const maxInstr = entries.length
        var qtyInstructions = { gte: 0, lte: maxInstr }
        if (build.or !== undefined)
            qtyInstructions.gte = 1
        if (build.and !== undefined)
            qtyInstructions.gte = maxInstr
        if (build.qty !== undefined) {
            const cmd = build.qty
            if (cmd.gte !== undefined) {
                if (cmd.gte >= 0)
                    qtyInstructions.gte = cmd.gte
                else
                    qtyInstructions.gte = maxInstr - cmd.gte
            }
            if (cmd.lte !== undefined) {
                if (cmd.lte >= 0)
                    qtyInstructions.lte = cmd.lte
                else
                    qtyInstructions.lte = maxInstr - cmd.lte
            }
        }
        // run through the array
        var aggregated = []
        for (const oneEntry of entries) {
            aggregated.push(...unitBuild({ Build: oneEntry }, useTools, alltools))
        }
        // count the occurrences
        var counts = {}
        for (const oneItem of aggregated) {
            //  console.log(JSON.stringify(oneItem))
            counts[oneItem.Name] = counts[oneItem.Name] ? counts[oneItem.Name] + 1 : 1;
        }
        // and filter the items
        useTools = []
        for (const [oneItem, count] of Object.entries(counts)) {
            if ((count >= qtyInstructions.gte) && (count <= qtyInstructions.lte)) {
                useTools.push(alltools[oneItem])
            }
        }
    }

    // invert selection
    //if (toProcess.not !== undefined) {

    //   }

    // process the entry        
    var resultingTools = useTools
    if (build.source !== undefined) {
        const source = getValueFor(build, 'source', '')
        const preprocess = getValueFor(build, 'preprocess', '').toLowerCase()
        var containsList = getValueFor(build, 'contains', [])
        if (typeof containsList === 'string') containsList = [containsList]
        const usetext = getValueFor(build, 'usetext', false)
        var equalsList = getValueFor(build, 'equals', [])
        if (typeof equalsList === 'string') equalsList = [equalsList]
        if (equalsList.length > 0) {
            for (var k = 0; k < equalsList.length; k++) {
                equalsList[k] = equalsList[k].toLowerCase()
            }
            //console.log("EQUALS " + JSON.stringify(equalsList))
        }

        const usegte = getValueFor(build, 'gte', null)
        const uselte = getValueFor(build, 'lte', null)
        const istype = getValueFor(build, 'istype', ['Tool'])
        resultingTools = []
        for (const entry of useTools) {
            if (istype.includes(entry.Type) || (istype === [])) {
                var srcData = ''
                var includeThis = false
                if (source === '')
                    srcData = JSON.stringify(toProcess)
                else {
                    srcData = getDataValue(entry, source)
                    if (usetext) srcData = JSON.stringify(srcData)
                }
                if (srcData === undefined) srcData = ""
                if (preprocess !== '') {
                    srcData = preprocessData(srcData, preprocess)
                } else {
                    if (typeof srcData !== 'string')
                        srcData = JSON.stringify(srcData)
                    srcData = srcData.toLowerCase()
                }
                if (equalsList.length > 0) {
                    //console.log("DATA " + JSON.stringify(srcData))
                    includeThis |= equalsList.includes(srcData)
                }
                if (usegte !== null) {
                    if (uselte !== null) {
                        includeThis |= ((srcData >= usegte) && (srcData <= uselte))
                    } else {
                        includeThis |= (srcData >= usegte)
                    }
                } else if (uselte !== null) {
                    includeThis |= (srcData <= uselte)
                }
                for (const searchentry of containsList) {
                    includeThis |= srcData.includes(searchentry.toLowerCase())
                }
                if (includeThis) {
                    resultingTools.push(entry)
                }
            }
        }
    }
    toProcess._built = true
    return resultingTools
}


ProcessToolData.processBuild = function (toProcess, alltools) {
    for (const [, entry] of Object.entries(toProcess)) {
        // derive the contents first, then the main item
        var resultTools = unitBuild(entry, alltools, alltools)
        // the build adds to existing ('hard-coded') tools
        if (entry.Contents === undefined) entry.Contents = []
        entry.Contents.push(...resultTools.map(el => el.Name))
        if ((entry._children !== undefined) && (entry._built !== true) && (entry._hidden !== true))
            this.processBuild(entry._children, alltools)
        // propagate any data to the children if required        
        if ((entry.Propagate !== undefined) && (entry._children !== undefined)) {
            // two steps to propagate parameters:  drop in the data (propData), with the _depth field removed.
            // and add the "propagate" entry to the children unless _depth dropped to 1.
            // the propagate is an easy way for a Top-Level view group to send display options down to all
            // of the children (e.g. the FullWidth setting)
            for (var [, child] of Object.entries(entry._children)) {
                if (entry.Propagate._depth > 1) {
                    var propNext = JSON.parse(JSON.stringify(entry.Propagate))
                    propNext._depth = propNext._depth - 1
                    Object.assign(child, { Propagate: propNext })
                }
                var propData = JSON.parse(JSON.stringify(entry.Propagate))
                delete propData._depth
                Object.assign(child, propData)
            }
        }
        // propagate the viewtag to all children
        // parent viewtags OVERRULE the child viewtags
        if ((entry.ViewTags !== undefined) && (entry._children !== undefined)) {
            for (var [, childVT] of Object.entries(entry._children)) {
                Object.assign(childVT, { ViewTags: JSON.parse(JSON.stringify(entry.ViewTags)) })
            }
        }
    }
}

/**************************************************************
 * Support for displaying tools (hidden and expanded items)
 */
ProcessToolData.GenerateViewInstructions = function (listOfItems, toolset, depth = -1) {
    if (listOfItems === undefined) return {}
    // generate a list of tool objects for the viewer (add _hidden=false, resolve group contents, and link to tools)
    var listOfTools = {}
    try {
        for (const entry of listOfItems) {
            var newToolEntry = {}
            newToolEntry._hidden = false
            newToolEntry._expanded = false
            newToolEntry._inherit = entry
            newToolEntry._children = {}
            var entryTool = toolset[entry]
            if (entryTool === undefined) {
                console.log("Undefined tool: " + entry)
            } else {
                if ((entryTool.Contents !== undefined) && (depth != 0)) {
                    newToolEntry._children = this.GenerateViewInstructions(entryTool.Contents, toolset, depth - 1)
                }
                listOfTools[entry] = newToolEntry
            }
        }
    } catch (er) {
        console.log("Error: GenerateViewInstructions")
        console.log(er.toString())
    }
    return listOfTools
}

ProcessToolData.getAddressForID = function (toollist, idctr, currentPath = []) {
    for (const [toolname, toolentry] of Object.entries(toollist)) {
        //  console.log(toolname)
        var thisPath = [...currentPath, toolname]
        if (toolentry._ctr == idctr) {
            return thisPath
        }
        const result = this.getAddressForID(toolentry._children, idctr, thisPath)
        if (result.length > 0) return result
    }
    return []
}

/**
 * Recursively count how many subentries are in the item's contents/children
 * 
 * @param {object} item - a tool object. if a group, continue to count across _children 
 * @param {boolean} skipHidden - (default=true): if true, means tools that have _hidden=true set will not count 
 * @param {int} maxdepth - maximum recursive depth, default 10. Used to avoid accidental loops 
 * @returns {int} - number of items contained 
 */
ProcessToolData.getToolCount = function (item, skipHidden = true, maxdepth = 10) {
    if (item === undefined) return 0
    try {
        if (maxdepth <= 0) {
            console.log("Recursion error in getGroupCount. Toolset data is incorrect.")
            return 0;
        }
        var ctr = 0;
        if (item.Type === 'Tool') {
            if (!(skipHidden && (item._hidden === true))) {
                ctr = 1;
            }
        }
        // Contents can exist for tools and groups. iterate through all
        // and count.  Tools that contain tools are rare, but exist: e.g. a hood may contain a spinner
        // groups are the natural containers of tools (e.g. PECVD) and thus almost always contain things (other groups & tools)
        if (item._children !== undefined) {
            for (const [, entry] of Object.entries(item._children)) {
                ctr = ctr + this.getToolCount(entry, skipHidden, maxdepth - 1)
            }
        }
    } catch (er) {
        console.log("getToolCount Error: " + er.toString())
        return 0
    }
    return ctr;
}

ProcessToolData.PopulateToolViewList = function (toollist, toolset, ctrObj = { ctr: 0 }) {
    // insert the tool objects into the toollist
    if (toollist === undefined) {
        return {}
    }
    for (const [toolname, tool] of Object.entries(toollist)) {
        if (tool._inherit !== undefined) {
            Object.assign(tool, toolset[toolname]);
        }
        tool._ctr = ctrObj.ctr;
        ctrObj.ctr++;
        this.PopulateToolViewList(tool._children, toolset, ctrObj);
    }
    return toollist;
}


ProcessToolData.FlattenToolViewList = function (toollist, options = { includeBuild: false, oneGroupPerRow: false, hierarchy: ['full'] }, depth = 1) {
    // flatten the tool list to make it displayable, showing only visible tools
    var toolsToShow = [];
    const isTop = options.hierarchy.includes('top')
    const isOne = options.hierarchy.includes('one')
    const isAll = options.hierarchy.includes('all') 
    const isFull = options.hierarchy.includes('full')
    const includeBuild = (options.includeBuild == true)

    //console.log("Flattening with " + JSON.stringify(options))
    for (var [, tool] of Object.entries(toollist)) {        

        if (isTop && (depth > 1) && (tool.Type !== "Tool")) tool._hidden = true
        if (isOne && (depth == 1) && (tool.Type !== "Tool")) tool._hidden = true
        if (tool._hidden !== true) {
            //console.log("Entry = " + tool.Name + " --> " + tool.Type)
            if (options.oneGroupPerRow === false) {
                if (!isAll || isFull || (tool.Type === "Tool")) {
                    toolsToShow.push(tool);
                } else if ((includeBuild == true) && (tool.Type === 'Build')) {
                    toolsToShow.push(tool);
                }
            } else if ((tool.Type === 'Tool') || (depth == 1)) {
                toolsToShow.push(tool);
            } else if ((options.oneGroupPerRow === true) && (depth > 1)) {
                toolsToShow.push(tool);
            }
        }
        if ((tool._expanded === true) || isAll || isFull || (isOne && (depth == 1))) {
            // expand in 3 cases:
            // _expanded flag is set, usually from user wanting to expand this group
            // the hierarchy is 'all', used in table views to flatten everything
            // at depth 1, the hierarchy is "one" which flattens only the top layer
            toolsToShow.push(...this.FlattenToolViewList(tool._children, options, depth + 1));
        }
    }
    if (depth == 1)
        toolsToShow = [toolsToShow]
    return toolsToShow;

}

ProcessToolData.FilterToolViewList = function (toollist, searchTerms) {
    // simple text filter of the tool list. also adds _searchcount for tools & groups (0=none, i.e. hidden)
    var matchCount = 0;
    // go through the tool list and find matches
    for (const [, tool] of Object.entries(toollist)) {
        const s = JSON.stringify(tool).toLowerCase();
        var match = false;
        if (searchTerms.length == 0) {
            match = true;
        } else {
            // if any one of the search terms are present, use the tool
            for (const entry of searchTerms) {
                if (s.includes(entry)) {
                    match = true;
                    break;
                }
            }
        }
        // but also include matches from any children
        var thisSearchCount = this.FilterToolViewList(
            tool._children,
            searchTerms
        );
        if (match) {
            thisSearchCount++;
        }
        if (thisSearchCount == 0) {
            tool._hidden = true;
        } else {
            tool._hidden = false;
        }
        tool._searchcount = thisSearchCount;
        matchCount += thisSearchCount;
    }
    return matchCount;
}


/**
 * Expand or contract tools in the toollist, based on the _ctr field in expandedList
 * @param {objecy} toollist - by ref, the object tool list {name:entry} to modify 
 * @param {array} expandedList - and array of _ctr values that should be expanded
 * 
 * Work through the toollist and for each entry, check if the entry._ctr value exists
 * in the expandedList.  if yes, then set _expanded true; otherwise false.
 * Also works through the entry._children
 * 
 * The use of _ctr helps to keep track of specific entries, rather than using the toolname
 * because the same toolname may show up in different groups
 */
ProcessToolData.setExpandedFlags = function (toollist, expandedList) {
    // set expanded for objects that have it, and iterate through child objects    
    for (const [, tool] of Object.entries(toollist)) {
        if (tool._expanded !== undefined) {
            if (tool._ctr === undefined) {
                tool._expanded = false
            } else {
                if (expandedList[tool._ctr])
                    tool._expanded = true
                else
                    tool._expanded = false
            }
        }
        if (tool._children !== undefined) {
            this.setExpandedFlags(tool._children, expandedList)
        }
    }
}

/**
 * Process the raw tool data from the API.
 * 
 * @param {object} rawTools - The API provided raw tool object 
 * @returns {object} inheritedTools 
 * 
 * Runs the {@link processInheritance} followed by {@link processBuild} and returns the
 * new tool list that is ready for GUI display 
 * 
 */
ProcessToolData.ProcessRawTools = function (rawTools) {
    //console.log("PTD - Processing Inheritance")
    var inheritedTools = this.processInheritance(rawTools, rawTools)
    //console.log("PTD - Processing Build")
    this.processBuild(inheritedTools, inheritedTools)
    //console.log("PTD - Processing Raw Done " + Object.keys(inheritedTools).length)
    return inheritedTools
}

/**
 * Take a flat display-list of tools and process the full-width for ViewPage display 
 * 
 * @param {array} toollist - A flat array of tools: [ [t1,t2,t3, ...] ]. Note the double [].
 * @returns {array} A nestted array split by full-width flag in each entry
 * 
 * Creates "line-breaks" in the [[tools]] array so each full width entry is its own [] subarray.
 * If t3 is full width, then for example [[t1,t2,t3,t4,t5,...]] would become [ [t1,t2],[t3],[t4,t5,...] ] 
 * 
 * When using expanding groups, where _children are pushed into the array when expanded, the behavior
 * can be unexpected:  because the parent is full-width, it is by itself. But the children are not
 * full-width. If subsequent entries are not full-width, then there is no clear distinction between
 * children and subsequent entries. Therefore, the last child needs to notify us of a required line-break
 * using a flag:  _BreakAfter = true  which flushes out the list but does not put the entry into a new line
 */
ProcessToolData.SplitIntoSingleRows = function (toollist) {
    var newlist = []
    var sublist = []
    for (const entry of toollist[0]) {
        var isFullWidth = false
        if (entry.FullWidth !== undefined) {
            isFullWidth = (entry.FullWidth === true)
            if (Array.isArray(entry.FullWidth)) isFullWidth = entry.FullWidth.includes(entry.Type)
        }
        if (isFullWidth) {
            // each full width entry gets to be its own [] array
            if (sublist.length > 0)
                newlist.push(sublist)
            newlist.push([entry])
            sublist = []
        } else {
            sublist.push(entry)
            if (entry._BreakAfter == true) {
                // flush list if required by entry
                newlist.push(sublist)
                sublist = []
            }
        }
    }
    if (sublist.length > 0)
        newlist.push(sublist)
    return newlist
}


/**
 * For debugging, turns a tool list object into a string of toolnames, and include the children
 * @param {object} tools - the object list of tools {name:details} 
 * @returns {string} - A comma separated string of the toolnames and children in []
 */
ProcessToolData.ToolObjToString = function (tools) {
    var s = ""
    for (var entry in tools) {
        s = s + entry + " "
        if ((tools[entry]._children !== undefined) && (Object.keys(tools[entry]._children).length > 0)) {
            s = s + "[" + ProcessToolData.ToolObjToString(tools[entry]._children) + "]  "
        }
    }
    return s
}

/**
 * For debugging, turns a tool array into a string of toolnames, and include nested arrays
 * @param {array} tools - Array of toolnames, can be nested with arrays 
 * @returns {string} - A space separated string of the toolnames and nested arrays in []
 */
ProcessToolData.ToolListToString = function (tools) {
    var s = "["
    for (var entry of tools) {
        if (Array.isArray(entry)) {
            s = s + ProcessToolData.ToolListToString(entry)
        } else {
            s = s + entry.Name + " "
        }
    }
    return s + "]"
}

module.exports = ProcessToolData
