<!--- 

The sidebar menu is dynamic, with different elements fed in via menuContent from the
menu store. The store can be manipulated by the rest of the application, to add or remove
menu items.

The basic element is a page link (with or without parameters) and divider. In addition
toggle items can be used to turn on/off behavior (via setting of a store variable) and change the icon/color/text. 

The model element allows for dialog boxes (rather than page calls). The details of the modal dialog
will need to be defined in the sidebar.js methods.

--->

<template>
  <v-navigation-drawer
    v-model="$store.state.menu.drawer"
    app
    clipped
    color="primary"
    dark
  >
    <v-list class="primaryselected--text" dense>
      <v-list-item-group v-model="$store.state.menu.selecteditem" mandatory>
        <template v-for="(item, index) in menuContent">
          <v-subheader v-if="item.type == 'title'" :key="index + '-sh'" class="primarytext--text">{{
            item.text
          }}</v-subheader>

          <v-divider v-if="item.type == 'divider'" :key="index + '-dv'" class="primarytext"></v-divider>

          <v-list-item
            v-if="item.type == 'toggle'"
            :key="index + '-tg'"
            @click="$store.dispatch('menu/processStateAction', item.action)"
          >
            <v-list-item-icon>
              <v-icon
                :color="getIconColor(item.states[item.value].iconcolor, 'primaryicons')"
                >{{ item.states[item.value].icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primarytext--text">
                {{ item.states[item.value].text }}
                <v-icon v-if="item.states[item.value].readonly" class="ml-2"
                  >mdi-eye-outline</v-icon
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="item.type == 'link'"
            :key="index + '-lk'"
            link
            @click="dispatchLink(item)"
          >
            <v-list-item-icon>
              <v-icon :color="getIconColor(item.iconcolor, 'primaryicons')">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primarytext--text">
                {{ item.text }}
                <v-icon v-if="item.readonly" class="ml-2" color="primaryicons">mdi-eye-outline</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="item.type == 'modal'"
            :key="index + '-md'"
            link
            @click="callMethod(item.modal, item.options)"
          >
            <v-list-item-icon>
              <v-icon :color="getIconColor(item.iconcolor, 'primaryicons')">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primarytext--text">
                {{ item.text }}
                <v-icon v-if="item.readonly" class="ml-2">mdi-eye-outline</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "App",

  /*data: () => ({
    spacemodel: [1]
  }),*/

  methods: {
    // *** Distributor for modal windows
    callMethod(target, params) {
      this[target](params);
    },
    getIconColor(value, defaultvalue) {
      if (value === undefined) return defaultvalue;
      return value;
    },
    async dispatchLink(item) {
      //console.log("dispatch in: " + moment().format("ss.SSS"));
      this.$store.commit("indicateLoading", true, { root: true });
      if (item.preaction !== undefined) {
        await this.$store.dispatch(item.preaction.call, item.preaction);
      }
      var target = item.page;
      if (target.length > 1) target = "/n" + target;
      if (this.$route.path !== target) this.$router.push(target);
      this.$store.commit("indicateLoading", false, { root: true });
      //console.log("dispatch out: " + moment().format("ss.SSS"));
    },
  },

  computed: {
    menuContent() {
      return this.$store.getters["menu/processMenuList"];
    },
  },
};
</script>
