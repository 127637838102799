import Vue from 'vue';
import Vuex from 'vuex';

import ViewStructuresDefault from "@/components/dataview/ViewStructure.js";

//import storemenu from '@/support/stores/storemenu';
import storedata from '@/support/stores/storedata';
import storetools from '@/support/stores/storetools';
import storeuser from '@/support/stores/storeuser';
import storemenu from '@/support/stores/storemenu';
//import moment from 'moment';

import dataloader from "@/api/dataloader";

import ColorTheme from './colortheme';

Vue.use(Vuex);

/**
 * Styling details.
 * 
 * COLORS:
 * primary
 *     Card headers
 *     Menu
 *     Buttons?
 * secondary
 *     Action buttons
 *     Expand buttons in simple Cards
 * tertiary
 *     wide-headers (to be less obvious than primary)
 * background
 *     background color
 * 
 * ADDITIONAL:
 * image-gradient
 *     gradient on images in main card
 * 
 * 
 */

export default new Vuex.Store({
    modules: {
        data: storedata,
        tools: storetools,
        user: storeuser,
        menu: storemenu,
    },
    /*****************************************************************************
     * The store mostly has modules. A few states are on this
     * top level store, if they are generally useful for managing the entire page
     * such as data loading indicators.
     */
    state: {
        // start out as 'loading' as it get stopped once we completed initialization
        loadingdata: true,
        // expand contains the state of the chevrons for view cards (e.g. login etc)
        expand: {
            login: true,
            newuser: false,
        },
        visible: {
            myinfo: false,
        },
        viewstructure: {},
        searchtext: "",
        refreshSeconds: 0,
        usedark: false,
        colorstyling_light: {
            /*            cardview: {
                            widegroup: { header: "tertiary white--text", background: "background", iconcolor: { expanded: "green", collapsed: "yellow", other: "pink" } },
                            group: { header: "primary white--text", iconcolor: { expanded: "pink", collapsed: "secondary", other: "white" } },
                            tool: { header: "white--text", iconcolor: { expanded: "green", collapsed: "pink", other: "yellow" } }
                        },*/
        },
        colorstyling_dark: {
            /*cardview: {
                widegroup: { header: "tertiary yellow--text", background: "background", iconcolor: { expanded: "purple", collapsed: "indigo", other: "white" } },
                group: { header: "primary yellow--text", iconcolor: { expanded: "pink", collapsed: "secondary", other: "white" } },
                tool: { header: "yellow--text", iconcolor: { expanded: "green", collapsed: "pink", other: "yellow" } }
            },*/
        }
    },
    getters: {
        isLoading: state => { return (state.loadingdata) },
        getSearchText: state => { return (state.searchtext) },
        isExpanded: state => (name) => {
            if (state.expand[name] === undefined) return true;
            return (state.expand[name]);
        },
        isVisible: state => (name) => {
            if (state.visible[name] === undefined) return true;
            return (state.visible[name]);
        },
        getStyling: state => {
            if (state.usedark)
                return state.colorstyling_dark
            else
                return state.colorstyling_light
        },
        getStylingByName: state => (source, defaultvalue = undefined) => {
            var entry = entry = state.colorstyling_light
            if (state.usedark)
                entry = state.colorstyling_dark
            for (const word of source.split('.')) {
                if (entry[word] === undefined) return defaultvalue
                entry = entry[word]
            }
            return entry
        },
        getViewStructure: state => { return (state.viewstructure) }
    },

    mutations: {
        setIsLoading(state, value) {
            state.loadingdata = value;
        },
        setDarkMode(state, isDark) {
            state.usedark = isDark;
        },
        setStyling(state, stylingStructure) {
            console.log("SET STYLING")
            console.log(JSON.stringify(stylingStructure))

            if (stylingStructure.dark !== undefined)
                state.colorstyling_dark = JSON.parse(JSON.stringify(stylingStructure.dark))
            if (stylingStructure.light !== undefined)
                state.colorstyling_light = JSON.parse(JSON.stringify(stylingStructure.light))
        },
        toggleExpand(state, name) {
            if (state.expand[name] === undefined) state.expand[name] = false; // as we assume true if undefined
            state.expand[name] = !state.expand[name];
        },
        setVisible(state, name) {
            state.visible[name] = true;
        },
        setHidden(state, name) {
            state.visible[name] = false;
        },
        setSearchText(state, value) {
            state.searchtext = value
        },
        indicateLoading(state, value) {
            state.loadingdata = value;
        },
        setViewStructure(state, value) {
            var valueClone = JSON.parse(JSON.stringify(value))            
            state.viewstructure = valueClone
        },
        modifyViewStructure(state, value) {
            var valueClone = JSON.parse(JSON.stringify(value))
            state.viewstructure = { ...state.viewstructure, ...valueClone };
        }
    },
    // ***************************************************************************
    actions: {
        /**
         * onCreate is called in Vue at the start, to trigger any
         * initialization actions for each store module
         * Initializations are async, but we don't await because
         * the app can continue loading in parallel until completed
         * whoever calls onCreate can then await results
         */
        async onCreate() {
            console.log("--> Initializing States...");
            // set default structures, then update with database
            this.commit("setViewStructure", ViewStructuresDefault);
            await this.dispatch('refreshViewStructure')
            await this.commit("setStyling", ColorTheme.styling);
            console.log("~ Data");
            await this.dispatch('data/initialize')
            console.log("~ User");
            await this.dispatch('user/initialize')
            console.log("~ Menu");
            await this.dispatch('menu/initialize')
            console.log("~ Tools");
            await this.dispatch('tools/initialize')
            console.log("--> Initializing States done");

            /*
            console.log("--> Launching the auto-refresh...");
            //var refreshSeconds = 10
            if (this.state.refreshSeconds > 0) {
                var self = this
                setInterval(function () {
                    console.log("reloading " + moment().format("HH:mm:ss"))
                    try {
                        // refresh if not busy
                        self.dispatch("data/refreshAllIfNotBusy");
                    } catch (ex) {
                        console.log("Error on Reload")
                        console.log(JSON.stringify(ex))
                    }
                    console.log("Done reloading " + moment().format("HH:mm:ss"))
                }, this.state.refreshSeconds * 1000);
            }
            */
        },
        async refreshViewStructure(context) {
            console.log("View Structures - refresh")
            // compile the tool list
            try {
                var allViewStructures = (await dataloader.getViewStructure())
                if (allViewStructures == undefined) {
                    console.log("Error downloading view structures")
                    return false
                }
                context.commit("setViewStructure", allViewStructures);
                console.log("View Structures - refresh done")
                return true
            } catch (ex) {
                console.log("View Structures - refresh error")
                return false
            }
        },
    },



});