import Vue from 'vue'
import vueDebounce from 'vue-debounce'
import VueRx from 'vue-rx'
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd.min";
import "vuejs-clipper/dist/vuejs-clipper.css";

import App from './App.vue'
import AsyncComputed from 'vue-async-computed'
import vuetify from './plugins/vuetify';

import router from './support/router';
import store from './support/mainstore';

 
Vue.use(VueRx);
Vue.use(VuejsClipper);

Vue.use(vueDebounce, { defaultTime: '500ms' })
Vue.use(AsyncComputed)

Vue.config.productionTip = false
 

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    async created() {
        // once created, trigger the stores to pre-init any data
        console.log('App Created - initializing async...')
        await this.$store.dispatch('onCreate')
        console.log('...initializing done')
        this.$store.commit('setIsLoading',false)
    }
}).$mount('#app')
