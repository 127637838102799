<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-app-bar>
        <v-btn icon color="green" @click.native="agree">
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn icon color="blue" @click="rotationDegrees -= 0.5">
          <v-icon>mdi-rotate-left</v-icon>
        </v-btn>
        <v-btn icon color="blue" @click="rotationDegrees += 0.5">
          <v-icon>mdi-rotate-right</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn icon color="red" class="ml-4" @click.native="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <clipper-fixed
        class="my-clipper"
        :src="imgURL"
        border-color="white"
        bg-color="black"
        :border="clipperParams.border"
        :min-scale="clipperParams.minscale"
        :grid="clipperParams.grid"
        shadow="rgba(0,0,0,0.5)"
        :rotate="rotationDegrees"
        :ratio="imageRatio"
        :area="clipperParams.area"
        ref="clipper"
        @load="loadedImage($event)"
      >
      </clipper-fixed>
    </v-card>
    <!--v-card-actions class="pt-0">
        <template v-if="showAccept">
          <v-btn color="primary darken-1" text @click.native="agree">{{
            options.accept
          }}</v-btn>
        </template>
        <v-spacer></v-spacer>
        <template v-if="showReject">
          <v-btn color="grey" text @click.native="cancel">{{
            options.reject
          }}</v-btn>
        </template>
      </v-card-actions-->
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    clipperParams: {
        grid: false,
        border: 3,
        minscale: 0.2,
        area: 80,
    },
    imgURL: "",
    options: {
      color: "primary",
      targetWidth: 500,
      targetHeight: 200,
      width: 800,
      zIndex: 200,
      accept: "Save",
      reject: "Cancel",
      params: {},
    },
    sidebar: true,
    rotationDegrees: 0,
  }),
  computed: {
    imageRatio() {
      return this.options.targetWidth / this.options.targetHeight;
    },
  },
  methods: {
    loadedImage() {
      //
      try {
        var modified = false;
        if (this.options.params.left !== undefined) {
          this.$refs.clipper.setTL$.next({
            left: this.options.params.left,
            top: this.options.params.top,
          });
          modified = true;
        }
        if (this.options.params.scale !== undefined) {
          this.$refs.clipper.setWH$.next(this.options.params.scale);
          modified = true;
        }
        if (this.options.params.rotation !== undefined) {
          this.rotationDegrees = this.options.params.rotation;
        } else {
          this.rotationDegrees = 0;
        }
        if (modified) {
          this.$refs.clipper.onChange$.subscribe(() => {});
        }
      } catch (ex) {
        console.log("ERROR IN OPENING IMAGE PRESET");
      }
    },
    open(imgURL, title, message, options) {
      this.dialog = true;
      this.imgURL = imgURL;
      this.title = title;
      this.message = message;      
      this.options = Object.assign(this.options, options);      
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.dialog = false;
      this.resolve({
        accept: true,
        image: this.$refs.clipper.clip({ wPixel: this.options.targetWidth }),
        params: {
          scale: this.$refs.clipper.bgWH$,
          left: this.$refs.clipper.bgTL$.left,
          top: this.$refs.clipper.bgTL$.top,
          rotation: this.rotationDegrees,
        },
      });
    },
    cancel() {
      this.loadedImage() // restore the original parameters before closing, as otherwise image "load" is not called next time we open
      this.dialog = false;      
      this.resolve({ accept: false });
    },
  },
};
</script>
