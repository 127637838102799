import dataloader from "@/api/dataloader";
import ProcessToolData from "@/support/processToolData.js"
import ViewStructures from "@/components/dataview/ViewStructure.js";

const state = () => ({
    RawTools: {},  // raw tools is what comes directly from the database without modification
    AllTools: {},  // a pre-processed version with inheritance and build included by running processToolData on RawTools
    UserTools: {},  // user tools are custom entries specific to the user (e.g. for processes or specific searches)
    ActiveViewList: {},
    ToolStatus: {},
    ViewSublists: {},
    isAtMIT: false
})

const mutations = {
    setIsMIT(state, FlagValue) {
        console.log("SETTING STATE " + FlagValue)
        state.isAtMIT = (FlagValue === true)
    },
    setToolData(state, tools) {
        console.log("StoreTools - Set tool list")
        state.AllTools = JSON.parse(JSON.stringify(tools))
    },
    setViewSublists(state, sublist) {
        console.log("StoreTools - Set view sublist")
        state.ViewSublists = JSON.parse(JSON.stringify(sublist))
    },
    setToolStatus(state, toolStatus) {
        console.log("StoreTools - Set tool status")
        state.ToolStatus = JSON.parse(JSON.stringify(toolStatus))
        console.log("tool status updating")
    },
    setRawToolData(state, tools) {
        console.log("StoreTools - Set raw tool list")
        state.RawTools = JSON.parse(JSON.stringify(tools))
    },
    updateSingleTool(state, instructions) {
        const toolid = instructions._id
        if (toolid === undefined) {
            console.log('updateSingleTool: id undefined')
            return
        }
        delete instructions._id
        var entryToModify = Object.keys(state.AllTools).find(key => state.AllTools[key]._id === toolid);
        if (entryToModify === undefined) {
            console.log('updateSingleTool: id not found')
            return
        }
        var toolEntry = JSON.parse(JSON.stringify(state.AllTools[entryToModify]))
        // go through instructions
        for (const [source, value] of Object.entries(instructions)) {
            const words = source.split(".");
            var entry = toolEntry;
            for (var k = 0; k < words.length - 1; k++) {
                if (entry[words[k]] !== undefined) {
                    entry = entry[words[k]];
                } else {
                    console.log("undefined " + words[k])
                    return
                }
            }
            entry[words[words.length - 1]] = value
        }
        state.AllTools[entryToModify] = toolEntry
        //state.AllTools = JSON.parse(JSON.stringify(state.AllTools))
        console.log("Updated single tool")
    },
    setActiveViewList(state, toollist) {
        console.log("StoreTools - Set active view list")
        console.log(JSON.stringify(toollist))
        state.ActiveViewList = JSON.parse(JSON.stringify(toollist))
    },
    toggleViewEntry(state, idCtr) {
        //console.log("Toggle Tool Group")
        //        console.log(JSON.stringify(state.ActiveViewList))
        var entry = { _children: state.ActiveViewList }
        var toolAddress = ProcessToolData.getAddressForID(state.ActiveViewList, idCtr)
        //console.log(JSON.stringify(toolAddress))
        for (const nextup of toolAddress) {
            entry = entry._children[nextup]
            if (entry === undefined) {
                console.log("Error in toggleViewEntry: Could not find id " + idCtr)
                return
            }
        }
        entry._expanded = !entry._expanded
    },
}

const getters = {
    getTools: (state) => {
        var tools = state.AllTools
        for (const [coralname, entry] of Object.entries(state.ToolStatus)) {
            if (tools[coralname] !== undefined) {
                if (entry.shutdowns > 0) {
                    tools[coralname].shutdowns = entry.shutdowns;
                    tools[coralname]._status = "shutdown";
                } else if (entry.problems > 0) {
                    tools[coralname].problems = entry.problems;
                    tools[coralname]._status = "problem";
                }
            }
        }
        return tools
    },
    getToolStatus: (state) => { return state.ToolStatus },
    isAtMIT: (state) => { console.log("GETTING STATE " + state.isAtMIT); return state.isAtMIT },
    //isAtMIT: (state) => { console.log("GETTING STATE " + state.isAtMIT); return true },
    getRawTools: (state) => {
        var tools = state.RawTools
        for (const [coralname, entry] of Object.entries(state.ToolStatus)) {
            if (tools[coralname] !== undefined) {
                if (entry.shutdowns > 0) {
                    tools[coralname].shutdowns = entry.shutdowns;
                    tools[coralname]._status = "shutdown";
                } else if (entry.problems > 0) {
                    tools[coralname].problems = entry.problems;
                    tools[coralname]._status = "problem";
                }
            }
        }
        return tools
        //return state.RawTools
    },
    getRawToolByName: (state) => (name) => { return state.RawTools[name] },
    getActiveViewList: (state) => { return state.ActiveViewList },
    getViewSublist: (state) => (name) => { return state.ViewSublists[name] }
}

const actions = {
    async initialize(context) {
        console.log("StoreTools - initializing tool data...")
        context.commit('setViewSublists', ViewStructures.viewSublists)
        await context.dispatch('refreshToolStatus');
        await context.dispatch('refreshTools');
        console.log("StoreTools - tool data processing")
        await context.dispatch('processTools');
        // set visible list        
        console.log("StoreTools - set visible to undefined")
        await context.dispatch("setVisibleList", undefined);
        console.log("StoreTools - initializing tool data done")
    },
    async refreshToolStatus(context) {
        console.log("Tool Status - refresh")
        // compile the tool list
        var allToolsStatus = (await dataloader.getToolsStatus())
        console.log("Status downloaded " + allToolsStatus)
        context.commit('setToolStatus', allToolsStatus)
        console.log("StoreTools - Tool refresh done " + Object.keys(allToolsStatus).length)
    },
    async refreshTools(context) {
        console.log("StoreTools - Tool refresh start")
        // compile the tool list
        var allToolsList = (await dataloader.getAllTools()).data
        var isMIT = dataloader.isMIT
        console.log("StoreTools - Tools downloaded")
        var toolObj = {};
        allToolsList.forEach(entry => {
            toolObj[entry.Name] = entry;
        })
        context.commit('setRawToolData', toolObj)
        context.commit('setIsMIT', isMIT)
        console.log("StoreTools - Tool refresh done " + Object.keys(toolObj).length)
    },
    processTools(context) {
        // set tools and visible list
        context.commit('setToolData', ProcessToolData.ProcessRawTools(context.getters.getRawTools))
    },
    setVisibleListDepth(context, data) {
        var depth = data.depth
        if (depth == undefined) depth = -1
        var toolNameList = data.toolNameList
        if (toolNameList === undefined) toolNameList = context.getters.getViewSublist('main')
        context.commit('setActiveViewList', ProcessToolData.GenerateViewInstructions(toolNameList, context.getters.getTools, depth))
    },
    setVisibleList(context, toolNameList) {
        if (toolNameList === undefined) toolNameList = context.getters.getViewSublist('main')
        context.commit('setActiveViewList', ProcessToolData.GenerateViewInstructions(toolNameList, context.getters.getTools, -1))
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}