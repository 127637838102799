<template>
  <v-app-bar app color="primary" clipped-left dark>
    <v-app-bar-nav-icon
      @click.stop="$store.commit('menu/toggleDrawer')"
      color="primarytext"
    />
    <span v-if="$vuetify.breakpoint.mdAndUp" class="mx-2 primarytext--text"
      >MIT.nano Equipment</span
    >

    <v-divider vertical class="mx-2 primarytext"></v-divider>
    <v-text-field
      hide-details
      prepend-icon="mdi-magnify"
      single-line
      v-debounce:700="runSearch"
    ></v-text-field>

    <v-spacer></v-spacer>
    <v-divider
      vertical
      class="mx-2 primarytext"
      v-if="$vuetify.breakpoint.mdAndUp"
    ></v-divider>
    <v-switch
      v-if="$store.getters['user/isLoggedIn']"
      v-model="darkmode"
      class="mt-6"
      color="primarytext"
      label="dark"
    ></v-switch>
    <v-btn
      class="mx-2"
      text
      fab
      small
      @click.stop="showHelp()"
      v-if="$vuetify.breakpoint.mdAndUp && $store.getters['user/isLoggedIn']"
    >
      <v-icon color="primarytext">mdi-help-circle-outline</v-icon>
    </v-btn>
    <v-divider
      vertical
      class="mx-2 primarytext"
      v-if="$vuetify.breakpoint.mdAndUp && $store.getters['user/isLoggedIn']"
    ></v-divider>

    <v-btn text @click="$router.push('/n/login')" v-if="$store.getters['tools/isAtMIT']">
      <template v-if="$store.getters['user/isLoggedIn']">
        <span class="mr-2 primarytext--text" v-if="$vuetify.breakpoint.mdAndUp">{{
          $store.getters["user/username"]
        }}</span>
        <v-icon color="primarytext">mdi-account</v-icon>
      </template>
      <template v-else>
        <span class="mr-2 primarytext--text" v-if="$vuetify.breakpoint.smAndUp"
          >LOGIN</span
        >
        <v-icon color="primarytext">mdi-account-off</v-icon>
      </template>
    </v-btn>
    <v-btn
      href="https://nanousers.mit.edu/access"
      target="_blank"
      text
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <span class="mr-2 primarytext--text text-capitalize">Get Started...</span>
      <v-icon color="primarytext">mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
//import dataloader from "@/api/dataloader.js";

export default {
  data: () => ({
    action: { loadingdata: false, canwrite: true, validuser: true },
    activespaceid: null,
    selectedOpen: false,
    useDark: false,
  }),
  asyncComputed: {
    config: {
      async get() {
        // get the setup data
        //var config = await dataloader.getInfo();
        console.log("STORE");
        //const infodata = await this.$store.getters["data/getDataList"]("info");
        /**
        if (infodata.data === undefined) {
          console.log("Data not yet available");
          return null;
        }
        if (infodata.data.error) {
          console.log("Error loading data");
          return null;
        }
        */
        console.log("STORE OK");
        //return infodata.data;
        return "Config";
      },
      default: [],
    },
    getSystemInfo: {
      async get() {
        return "FAB.nano Deposition";
      },
    },
  },
  computed: {
    getActiveData() {
      // fill in data about help here
      return { name: "Help Content", location: "4U", description: "Test" };
    },
    darkmode: {
      get: function () {
        return this.useDark;
      },
      set: function (newval) {
        this.useDark = newval;
        this.$store.commit("setDarkMode", newval);
        this.$vuetify.theme.dark = newval;
      },
    },
  },
  methods: {
    runSearch(value) {
      // the debounced search text gets put into the store, and other pages can then pick it up & proceed
      this.$store.commit("setSearchText", value);
    },

    async gotoNewLocation() {
      console.log("switch to somewhere");
    },

    async showHelp() {
      var aMessage = [];
      aMessage.push({ textbr: "About", textclass: "font-weight-bold" });
      aMessage.push({
        divider: true,
        textbr: "Tool overview pages. This is a prototype - J. Scholvin 1/2023",
      });
      aMessage.push({ textbr: " " });
      aMessage.push({ textbr: "Version", textclass: "font-weight-bold" });
      aMessage.push({ divider: true, textbr: "Prototype Draft - Jan 2023" });
      aMessage.push({ textbr: "" });
      aMessage.push({ textbr: " " });
      aMessage.push({ textbr: "Known Bugs", textclass: "font-weight-bold" });
      aMessage.push({ divider: true });
      aMessage.push({ textbr: "Menu choice on load" });
      aMessage.push({ textbr: "Expand card messed up on initial load" });
      aMessage.push({ textbr: " " });
      aMessage.push({
        textbr: "How do I use this page?",
        textclass: "font-weight-bold",
      });
      aMessage.push({ divider: true });
      aMessage.push({
        textbr:
          "The goal of this website is to help users find the right equipment in MIT.nano.  You can navigate on the left to the main tool categories, or view equipment in different table forms.",
      });
      await this.$root.$confirmdialog("About - MIT.nano Tool Pages", aMessage, {
        color: "secondary",
        reject: "",
        accept: "Okay",
      });
    },
  },
};
</script>
