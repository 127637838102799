const moment = require("moment");

var dataPrep = {}

/**
 * Apply a filter function to the data (subject to parameters if needed)
 * @param {string} filtername - case sensitive name of the function 
 * @param {*} dataentry - the input data to pass on to the filter
 * @param {object} params - (optional) an object of parameters for the filter  
 * @returns {*} - the filtered value, or the original dataentry if no filter applied 
 */
dataPrep.runFilter = function (filtername, dataentry, params) {
    filtername = filtername.toLowerCase()
    if (dataentry === undefined) return undefined
    if (filtername === "makestafflist") {
        return this.makeStaffList(dataentry);
    }
    if (filtername === "concatarray") {
        return dataentry.join(", ");
    }
    if (filtername === "makestafflistsingletype") {
        return this.makeStaffListSingleType(dataentry);
    }
    if (filtername === "makecategorylist") {
        return this.makeCategoryList(dataentry);
    }
    if (filtername === "makefeatureslist") {
        return this.makeFeaturesList(dataentry);
    }
    if (filtername === "makegaslist") {
        return this.makeGasList(dataentry, false);
    }
    if (filtername === "makeratelist") {
        return this.makeRateList(dataentry, false);
    }

    if (filtername === "makegaslistshort") {
        return this.makeGasList(dataentry, true);
    }
    if (filtername === "makealternativelist") {
        return this.makeAlternativeList(dataentry);
    }
    if (filtername === "makematerialslist") {
        return this.makeMaterialsList(dataentry);
    }
    if (filtername === "makedocumentlist") {
        return this.makeDocumentList(dataentry);
    }
    if (filtername === "makedatetime") {
        return this.makeDateTime(dataentry);
    }
    if (filtername === "makedaycount") {
        return this.makeDayCount(dataentry);
    }
    if (filtername === "putousd") {
        return this.makePuToUSD(dataentry, 8.5);
    }
    if (filtername === "datetoyears") {
        return this.dateToYears(dataentry);
    }
    if (filtername === "contains") {
        return params.value.includes(dataentry)
    }
    console.log("unknown filter " + filtername)
    return dataentry;
}

dataPrep.dateToYears = function (dataentry) {
    var d = moment(dataentry)
    var dt = (moment().valueOf() - d.valueOf()) / 1000 / 3600 / 24 / 365
    if (dt < 1) {
        return Math.round(dt * 25) / 25
    }
    return Math.round(dt * 10) / 10
}

dataPrep.makePuToUSD = function (dataentry, multiplier) {
    if (dataentry == "") return ""
    // find the '/' and multiply number before that by
    try {
        var completed = ""
        var sections = dataentry.split("/")
        for (var k = 0; k < sections.length; k++) {
            if (k > 0) completed = completed + "/"
            var s = sections[k]
            if (k < sections.length - 1) {
                var idx = Math.max(s.lastIndexOf(" "), s.lastIndexOf("+"))
                if (idx >= 0) {
                    completed = completed + s.substring(0, idx + 1)
                    s = s.substring(idx + 1)
                }
                var pu = parseFloat(s)
                if (!isNaN(pu)) {
                    s = "$" + Math.round(multiplier * pu * 100) / 100
                }
            }
            completed = completed + s
        }
        return completed
    } catch (ex) {
        return "Invalid: " + dataentry
    }
}

dataPrep.makeDayCount = function (dataentry) {
    if (dataentry == "") return ""
    try {
        var daysToShow = moment(moment().valueOf() - dataentry) / 1000 / 3600 / 24
        if (daysToShow < 1) return "< 1 day"
        if (daysToShow > 30) return Math.ceil(daysToShow / 30.5 * 5) / 4 + " months"
        return Math.ceil(daysToShow) + " days"
    } catch (ex) {
        return "Not a date"
    }
}

dataPrep.makeDateTime = function (dataentry) {
    if (dataentry == "") return ""
    try {
        //console.log(dataentry)
        return moment(dataentry).format("ddd MMM DD @ hh:mma")
    } catch (ex) {
        return "Not a date"
    }
}
dataPrep.makeStaffList = function (dataentry) {
    var s = "";
    if (dataentry.Primary !== undefined)
        s = Object.keys(dataentry.Primary).join(", ");
    if (dataentry.Backup !== undefined)
        s = s + " (" + Object.keys(dataentry.Backup).join(", ") + ")";
    return s;
}

//dataPrep.makeStaffListByDay = function (dataentry) {

//}

dataPrep.makeStaffListSingleType = function (dataentry) {
    var s = "";
    if (dataentry !== undefined)
        s = Object.keys(dataentry).join(", ");
    return s;
}

dataPrep.makeCategoryList = function (dataentry) {
    var s = "";
    var categories = Object.keys(dataentry);
    for (var k = 0; k < categories.length; k++) {
        if (k > 0) s = s + " & ";
        s = s + categories[k];
        if (dataentry[categories[k]] !== true) {
            var subcategories = Object.keys(dataentry[categories[k]]);
            s = s + " / ";
            for (var m = 0; m < subcategories.length; m++) {
                if (m > 0) s = s + ", ";
                s = s + subcategories[m];
            }
        }
    }
    return s;
}
dataPrep.makeFeaturesList = function (dataentry, showNotes = true) {
    var s = "";
    var features = Object.keys(dataentry);
    for (var k = 0; k < features.length; k++) {
        if (k > 0) s = s + ", ";
        s = s + features[k];
        if (dataentry[features[k]] !== true) {
            // name: value units
            if (dataentry[features[k]].value !== undefined) {
                s = s + " = " + dataentry[features[k]].value;
                if (dataentry[features[k]].units !== undefined) {
                    s = s + " " + dataentry[features[k]].units;
                }
            } else {
                if (dataentry[features[k]].units !== undefined) {
                    s = s + " = " + dataentry[features[k]].units;
                }
            }
            if (dataentry[features[k]].note !== undefined && showNotes) {
                s = s + " (" + dataentry[features[k]].note + ")";
            }
        }
    }
    return s;
}
dataPrep.makeGasSublist = function (gaslist, showSub) {
    var gases = Object.keys(gaslist);
    var s = "";
    for (var k = 0; k < gases.length; k++) {
        if (k > 0) s = s + ", ";
        s = s + gases[k];
        if (gaslist[gases[k]] !== true && showSub) {
            if (gaslist[gases[k]].flow !== undefined) {
                s =
                    s +
                    " (" +
                    gaslist[gases[k]].flow.value +
                    " " +
                    gaslist[gases[k]].flow.units +
                    ")";
            }
        }
    }
    return s;
}
dataPrep.makeGasList = function (dataentry, omitCleaning = false, showSub = true) {
    var s = "";
    //Gases: {ProcessGases: {Cl2:{flow:{value:50, units:"sccm"}},BCl3:{flow:{value:50, units:"sccm"}}}, CleaningGases: {O2:true, CHF3:true} },
    if (dataentry.ProcessGases !== undefined) {
        s = this.makeGasSublist(dataentry.ProcessGases, showSub);
    }
    if ((dataentry.CleaningGases !== undefined) && (omitCleaning === false)) {
        if (s !== "") s = s + "; ";
        s =
            s +
            "(" +
            this.makeGasSublist(dataentry.CleaningGases, showSub) +
            " for cleaning)";
    }
    return s;
}

dataPrep.makeRateList = function (dataentry, showSub = true) {
    var s = ""
    for (const [matl, entry] of Object.entries(dataentry)) {
        if (s != "") s = s + ", ";
        s = s + matl;
        if (entry !== true && showSub) {
            if (entry.rate !== undefined) {
                s =
                    s +
                    " (" +
                    entry.rate.value +
                    " " +
                    entry.rate.units +
                    ")";
            }
        }
    }
    return s;
}


dataPrep.makeAlternativeList = function (altentry) {
    var alts = Object.keys(altentry);
    var resultlist = [];
    for (var k = 0; k < alts.length; k++) {
        var s = alts[k] + " (" + altentry[alts[k]].name;
        if (altentry[alts[k]].note !== undefined)
            s = s + ", " + altentry[alts[k]].note;
        s = s + ")";
        resultlist.push(s);
    }
    return resultlist;
}
dataPrep.makeMaterialsList = function (materialentry) {
    var categories = Object.keys(materialentry);
    var resultlist = [];
    for (var k = 0; k < categories.length; k++) {
        var s = categories[k] + ": ";
        var contents = materialentry[categories[k]];
        var materials = Object.keys(contents);
        for (var m = 0; m < materials.length; m++) {
            if (m > 0) s = s + ", ";
            s = s + materials[m];
            if (
                contents[materials[m]] !== true &&
                contents[materials[m]].note !== undefined
            ) {
                s = s + " (" + contents[materials[m]].note + ")";
            }
        }
        resultlist.push(s);
    }
    return resultlist;
}
dataPrep.makeDocumentList = function (docentry) {
    var docsections = Object.keys(docentry);
    var resultlist = [];
    for (var k = 0; k < docsections.length; k++) {
        var subsection = docentry[docsections[k]];
        var contents = subsection.Contents;

        for (var m = 0; m < contents.length; m++) {
            var entryToAdd = { Icon: contents[m].Icon, Title: contents[m].Label };
            if (m == 0) entryToAdd.header = subsection.Label;
            if (contents[m].Details !== undefined)
                entryToAdd.FixedText = contents[m].Details;
            resultlist.push(entryToAdd);
        }
    }
    return resultlist;
}


dataPrep.getEntryFromWords = function (entry, words, undefAlert = false) {
    for (var k = 0; k < words.length; k++) {
        if (entry[words[k]] !== undefined) {
            entry = entry[words[k]];
        } else {
            if (undefAlert) // && field.filter === undefined)
                return "undefined " + words[k];
            return undefined;
        }
    }
    return entry
}


dataPrep.getTextDataForTool = function (field, tooldata) {
    if (tooldata === undefined) return "undefined tool";
    if (field === undefined) return "undefined field";
    if (field === null)
        return "null field";

    if (typeof field !== "object") {
        // simple content - turn into object
        field = { source: field, filter: undefined };
    }
    // split and get data
    const sourceAddress = field.source.split(".");
    // if build is included, then generate the array first. Otherwise, only pick one item
    var result = ""
    if (field.build !== undefined) {
        const buildAddress = field.build.source.split(".");
        var datasetToConvert = tooldata._children
        result = []
        for (const [key] of Object.entries(datasetToConvert)) {
            var childentry = datasetToConvert[key]
            var includeThis = dataPrep.getEntryFromWords(childentry, buildAddress)
            //console.log(JSON.stringify(includeThis))
            if (field.build.includeif !== undefined) {
                includeThis = this.runFilter(field.build.includeif, includeThis, field.build.filterparams);
            }
            if (includeThis) {
                result.push(dataPrep.getEntryFromWords(childentry, sourceAddress))
            }
        }
        //console.log(JSON.stringify(result))
    } else {
        result = dataPrep.getEntryFromWords(tooldata, sourceAddress)
    }
    // run the filter over the result
    if (field.filter !== undefined) {
        result = this.runFilter(field.filter, result, field.filterparams);
    }
    // done - return with pre or suffic data
    if (field.prefix !== undefined)
        result = field.prefix + result
    if (field.suffix !== undefined)
        result = result + field.suffix
    return result;
}

module.exports = dataPrep
