/*********************************************
 * 
 *  All relevant code to connect to the API
 * 
 *  No other part of the code will access the http API. This way, all URLs are collected
 *  and abstracted out in this module.
 * 
 */

import http from "@/api/http-common";
import httpImages from "@/api/http-images";

var dataload = {};

dataload.isMIT = false
dataload.ToolEditKey = undefined

/*
dataload.routePageTo = function (newPage) {
    if (this.$router === undefined) {
        this.$router.push(newPage);
        return true;
    }
    if (this.$router.path !== newPage) {
        this.$router.push(newPage);
        return true;
    }
    return false;
}
*/

/**
 * Attempt a login of the user
 * @param {string} username - string of the username
 * @param {string} password - string of the password
 * @returns {object} - a result object. 
 * 
 * The function pick out the "editkey" from the result
 * as it is required by the server for any edit requests
 */
dataload.login = async function (username, password) {
    console.log("login user");
    var requestParams = { username: username, password: password };
    dataload.username = username
    var result = (await http.post('/auth/login', requestParams)).data
    dataload.ToolEditKey = result.editkey
    return result
}
/**
 * Log out the current user
 * @returns {object} - Error=false if logged out, or error message
 */
dataload.logout = async function () {
    console.log("logout user");
    dataload.username = undefined
    dataload.ToolEditKey = "invalid"
    return (await http.get('/auth/logout')).data;
}

/**
 * Find out from the API server if we are logged in. Returns the tool edit key if so.
 * @returns {object} - Success=true, editkey, username.
 * 
 * When a browser tab reload is done, the GUI does not know that we are still logged
 * in. This check helps to determine that, allowing us to re-establish the login data
 * and save the auth data and editkey
 */
dataload.getUser = async function () {
    console.log("check login");
    var result = (await http.get('/auth/check')).data;
    dataload.ToolEditKey = result.editkey
    return result
}

/**
 * OUTDATED???
 * @param {*} toolname 
 * @returns 
 */
dataload.getImageData = async function (toolname) {
    console.log("load tool image data " + toolname);
    return (await http.get("/image/" + toolname))
}
/**
 * Loads the raw image from the server
 * @param {string} fileid - the code-portion of the image to load 
 * @returns {Buffer} - the base64 image string
 */
dataload.getImage = async function (fileid) {
    var response = (await httpImages.get("/raw/img_" + fileid + ".jpg", { responseType: 'arraybuffer' })).data
    return Buffer.from(response, 'binary').toString('base64')
}

/**
 * Loads the downsized thumbnail image from the server
 * @param {string} fileid - the code-portion of the image to load 
 * @returns {Buffer} - the base64 image string
 */
dataload.getImageProcessed = async function (fileid) {
    var response = (await httpImages.get("/img_" + fileid + ".jpg", { responseType: 'arraybuffer' })).data
    return Buffer.from(response, 'binary').toString('base64')
}

/**
 * Upload a new image to the server
 * @param {object} formdata - 
 * @returns {object} - if all good, Error=false, otherwise Message contains error string 
 */
dataload.sendImageForUpload = async function (formdata) {
    console.log("upload image");
    return (await http.post("/image/set", formdata, { headers: { "Content-Type": "multipart/form-data" } })).data;
}


/*dataload.saveUserState = async function (configuration) {
    console.log("saving user config");
    var requestParams = { userdata: configuration };
    console.log("--> saving user state: "+JSON.stringify(requestParams))
    return (await http.post('/users/state', requestParams)).data;
}
*/
dataload.changePassword = async function (oldpass, newpass) {
    console.log("updating password");
    if (dataload.username === undefined) return { error: true, message: "Not logged in" }
    var requestParams = { username: dataload.username, oldpass: oldpass, newpass: newpass };
    return (await http.post('/auth/passwd', requestParams)).data;
}

/**
 * Retrieve all tools from the database
 * @returns {object} - Result object, with error=t/f, data={name:entry} of all tools, isMIT: show access as at MIT, dt: processing time in milliseconds  
 */
dataload.getAllTools = async function () {
    var allTools = (await http.get('/tools/all')).data
    dataload.isMIT = allTools.isMIT
    return allTools
}

/**
 * Retrieve the coral tool status for all tools
 * @returns {object} - tool status data 
 */
dataload.getToolsStatus = async function () {
    var allTools = await http.get('/tools/status')
    return allTools.data
}

dataload.sendToolUpdateData = async function (instructions) {
    console.log("updating tool data");
    //if (dataload.username === undefined) return { error: true, message: "Not logged in" }
    var requestParams = { key: dataload.ToolEditKey, instructions: instructions };
    return (await http.post('/tools/edit', requestParams)).data;
}
dataload.sendToolReplacement = async function (instructions) {
    console.log("updating tool data");
    //if (dataload.username === undefined) return { error: true, message: "Not logged in" }
    var requestParams = { key: dataload.ToolEditKey, instructions: instructions, replacedata: true };
    return (await http.post('/tools/edit', requestParams)).data;
}
dataload.sendNewTool = async function (instructions) {
    console.log("updating tool data");
    //if (dataload.username === undefined) return { error: true, message: "Not logged in" }
    var requestParams = { key: dataload.ToolEditKey, instructions: instructions };
    return (await http.post('/tools/add', requestParams)).data;
}

/**
 * Get view structure
 * @returns {object} - Result object, with error=t/f, data={viewstructures object}
 */
dataload.getViewStructure = async function () {
    console.log("download view structure");
    var viewStructure = (await http.get('/config/views')).data
    if (viewStructure == undefined) return undefined
    if (viewStructure.error == true) return undefined
    console.log("download view structure - DONE");    
    return viewStructure.data[0]
}
/**
 * Set view structure
 * Warning: this is global for all users, and can damage the website if bad data is uploaded
 */
dataload.setViewStructure = async function (viewStructure) {
    console.log("updating view structure");
    //if (dataload.username === undefined) return { error: true, message: "Not logged in" }
    var requestParams = { key: dataload.ToolEditKey, viewstructure: viewStructure, replacedata: true };
    return (await http.post('/config/views', requestParams)).data;
}

export default dataload;