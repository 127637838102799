<!-- This card is only for uploading tool images, not other image content -->
<template>
  <v-card color="primary" class="ma-auto" outlined max-width="500">
    <v-card-title class="white--text" v-if="toolnameToShow == ''"
      >Select Tool</v-card-title
    >
    <v-list v-if="toolnameToShow == ''">
      <v-list-item>
        <v-combobox
          class="ml-4 white"
          mandatory
          v-model="categorySelected"
          :items="CategoryNames"
          label="Category"
        ></v-combobox>
        <v-combobox
          clearable
          v-model="toolSelected"
          :items="Toolnames"
          label="Tool to edit images for"
          prepend-icon="mdi-pencil"
          @change="setNewTool"
        ></v-combobox>
      </v-list-item>
    </v-list>
    <!-- Header -->
    <v-card-title class="white--text">Photo: {{ toolSelected }}</v-card-title>
    <!-- Card Image -->
    <input
      type="file"
      ref="imagefile"
      style="display: none"
      accept="image/*"
      :capture="captureType"
      @change="uploadPhoto($event)"
    />

    <v-img
      :src="resultImage"
      :width="targetWidth"
      :height="targetHeight"
      class="blue-grey lighten-5"
      @click="imageAction"
    />
    <v-progress-linear
      indeterminate
      color="blue-grey accent-1"
      height="6"
      v-if="isLoading || isSaving"
    ></v-progress-linear>
    <!-- BODY STARTS HERE -->
    <!-- Text Descriptor / Subheader One-Liner -->
    <v-list class="py-0" :disabled="isLoading || isSaving">
      <v-list-item>
        <v-btn
          icon
          color="primary"
          large
          @click="addImageFromCamera"
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-btn icon color="primary" large @click="addImageFromFile">
          <v-icon>mdi-image</v-icon>
        </v-btn>
        Replace New
        <v-spacer />
        Browse
        <v-btn icon color="primary" large @click="$refs.imagefile.click()">
          <v-icon>mdi-folder-search</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider class="mb-1" />
      <v-subheader class="py-0 my-0"
        >Optional details to make the image easier to find and reuse:</v-subheader
      >
      <v-list-item>
        <v-text-field
          label="Image Name (typically CORAL tool name)"
          prepend-icon="mdi-label-outline"
          v-model="imageData_Name"
        ></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field
          label="Caption"
          prepend-icon="mdi-card-text-outline"
          v-model="imageData_Caption"
        ></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field
          label="Location"
          prepend-icon="mdi-map-marker-outline"
          v-model="imageData_Location"
        ></v-text-field>
      </v-list-item>
      <v-divider class="mb-3" />
      <v-list-item class="pb-3 mb-2">
        <v-btn icon color="primary" large @click="uploadImage">
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
        Upload Image Changes
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
//const sharp = require('sharp');
import dataloader from "@/api/dataloader.js";
const _ = require("lodash");

export default {
  //  https://george-hadjigeorgiou97.medium.com/step-by-step-custom-drag-drop-upload-component-in-vuetify-vue-2-43c99794643d
  // https://www.codegrepper.com/code-examples/html/click+on+icon+to+open+file+upload+in+vue+js
  //https://javascript.plainenglish.io/resize-an-image-using-nodejs-f5e57ac10419

  data: () => ({
    imgURL: "",
    myImage: "",
    resultImage: "",
    captureType: "camera",
    targetWidth: 500,
    targetHeight: 200,
    imageParams: {},
    rawImageID: "",
    toolImageID: "",
    isLoading: false,
    isSaving: false,
    rawImageIsNew: false,
    rawImageIsNewPrevious: false,
    toolSelected: "",
    imageData_Name: "",
    imageData_Caption: "",
    imageData_Location: "",
    categorySelected: "All",
    ImagePrefixString: "data:image/jpg;base64,",
    categoryListBase: {
      All: true,
      "Fab Tools": ["FabNanoGroup"],
      "Char. Tools": ["CharacterizationNanoGroup"],
      Groups: true,
    },
  }),
  mounted() {
    this.$store.dispatch("menu/setQuickLink", "home");
    //this.downloadImage("22010508511aedf8da659c");
    if (this.toolnameToShow != "") {
      console.log("Set Photo " + this.toolnameToShow);
      this.toolSelected = this.toolnameToShow;
      this.setNewTool();
    }
  },

  props: {
    toolnameToShow: {
      default() {
        return "";
      },
      type: String,
    },
  },
  watch: {
    toolnameToShow: function (val) {
      console.log("New Photo " + val);
      this.toolSelected = JSON.parse(JSON.stringify(val));
      this.setNewTool();
    },
  },
  computed: {
    Toolnames() {
      var toolnames = [];
      for (const [, value] of Object.entries(this.FilteredTools)) {
        toolnames.push(value.Name);
      }
      return toolnames;
    },
    CategoryNames() {
      var namelist = {};
      for (const [, value] of Object.entries(this.AllTools)) {
        if (value.Specs !== undefined && value.Specs.Category !== undefined) {
          for (const catName of Object.keys(value.Specs.Category)) {
            namelist[catName] = 1;
          }
        }
      }
      return [...Object.keys(this.categoryListBase), ...Object.keys(namelist).sort()];
    },
    FilteredTools() {
      var tools = this.AllTools;
      var results = {};
      console.log("filtering tools");
      if (this.categoryListBase[this.categorySelected] === true) {
        // special cases: all
        if (this.categorySelected === "All") {
          return tools;
        }
        // special cases: all tools
        if (this.categorySelected === "Tools") {
          for (const [key, value] of Object.entries(this.AllTools)) {
            if (value.Type === "Tool") results[key] = { Name: value.Name };
          }
        }
        // special cases: all non-tools
        if (this.categorySelected === "Groups") {
          for (const [key, value] of Object.entries(this.AllTools)) {
            if (value.Type !== "Tool") results[key] = { Name: value.Name };
          }
        }
      } else if (this.categoryListBase[this.categorySelected] !== undefined) {
        // use a set of tools
        const includeThese = this.categoryListBase[this.categorySelected];
        for (const entry of includeThese) {
          var addList = tools[entry].Contents;
          if (addList !== undefined) {
            for (const item of addList) {
              if (results[item] === undefined) results[item] = { Name: item };
            }
          }
        }
      } else {
        // filter by category name
        for (const [key, value] of Object.entries(this.AllTools)) {
          if (
            value.Specs !== undefined &&
            value.Specs.Category !== undefined &&
            value.Type === "Tool"
          ) {
            if (value.Specs.Category[this.categorySelected] !== undefined)
              results[key] = { Name: value.Name };
          }
        }
      }
      return results;
    },

    AllTools() {
      console.log("~~~ get all tools");
      var AllToolsToUse = this.$store.getters["tools/getTools"];
      return AllToolsToUse;
    },
  },

  methods: {
    async setNewTool() {
      // a new tool was picked. try to find it in database to reset image & image details
      if (this.toolSelected === null) return;
      console.log("New tool to load");
      console.log(this.toolSelected);
      this.isLoading = true;
      var toolData = await dataloader.getImageData(this.toolSelected);
      console.log("loading complete");
      if (toolData.error) {
        console.log("Error loading tool image data");
        this.isLoading = false;
        return;
      }
      console.log(JSON.stringify(toolData));
      toolData = toolData.data;
      if (toolData.data.length < 1) {
        console.log("No tool image data");
        this.toolImageID = "";
        this.isLoading = false;
        this.imageParams = {};
        this.imageData_Name = "";
        this.imageData_Caption = "";
        this.imageData_Location = "";
        this.rawImageID = "";
        if (this.myImage) URL.revokeObjectURL(this.myImage);
        this.imgURL = "";
        //this.resultImage = "";
        this.isLoading = false;

        return;
      }
      toolData = toolData.data[0];
      console.log(JSON.stringify(toolData));

      if (toolData.ToolName === undefined) {
        console.log("Not found");
        // does not yet exist in database?
        this.isLoading = false;
        return;
      }
      console.log("Set new image data");
      // set the form data & start loading images
      this.imageParams = toolData.ImageParams;
      this.imageData_Name = toolData.Name;
      this.imageData_Caption = toolData.Caption;
      this.imageData_Location = toolData.Location;
      this.toolImageID = toolData.FileID;
      this.rawImageID = toolData.DerivedFrom;
      if (this.rawImageID === null) {
        this.rawImageID = this.toolImageID;
      }
      if (this.myImage) URL.revokeObjectURL(this.myImage);
      this.imgURL = "";
      //this.resultImage = "";
      this.isLoading = false;
      this.downloadImage();
    },
    async uploadImage() {
      if (this.isLoading || this.isSaving) return;
      this.isSaving = true;
      // upload the images
      // prepare request parameters
      console.log("IMG " + JSON.stringify(this.myImage).substring(0, 20));
      var formData = new FormData();
      if (this.myImage !== undefined && this.rawImageIsNew === true) {
        console.log("attaching raw image");
        console.log(JSON.stringify(this.myImage));
        await formData.append(
          "rawimage",
          await fetch(this.myImage).then((r) => r.blob()),
          "rawimage"
        );
      }
      formData.append("toolname", this.toolSelected);
      formData.append("toolimage", this.resultImage.slice(this.ImagePrefixString.length));
      if (this.toolImageID !== "") formData.append("fileid", this.toolImageID);
      if (this.rawImageID !== "") formData.append("rawfileid", this.rawImageID);
      console.log(this.rawImageID);
      console.log(this.toolImageID);
      formData.append("name", this.imageData_Name);
      formData.append("caption", this.imageData_Caption);
      formData.append("location", this.imageData_Location);
      console.log(JSON.stringify(this.imageParams));
      console.log(JSON.stringify(this.resultImage));
      formData.append("params", JSON.stringify(this.imageParams));
      var result = await dataloader.sendImageForUpload(formData);
      console.log("done upload " + JSON.stringify(result));
      if (result.error === false) {
        result = result.data;
        this.rawImageID = result.DerivedFrom;
        this.toolImageID = result.FileID;
      }
      this.isSaving = false;
      this.rawImageIsNew = false;
      this.rawImageIsNewPrevious = false;
      // propagate the new image to the tool data
      // disable buttons (so we can't doulbe upload)
      // --> this.toolImageID

      // look up tool ID from name
      var tool = this.AllTools[this.toolSelected];
      if (tool === undefined) return;
      var modifications = {
        _id: tool._id,
        ImageName: "img_" + this.toolImageID + ".jpg",
      };
//      console.log("--> image modifying "+JSON.stringify(modifications))
      this.$store.commit("tools/updateSingleTool", modifications);
    },
    async downloadImage() {
      // don't load if still busy
      if (this.isLoading || this.isSaving) return;
      var fileid = this.toolImageID;
      var rawid = this.rawImageID;
      this.isLoading = true;
      console.log("LOAD " + fileid);
      var imageSmall = await dataloader.getImageProcessed(fileid);
      this.resultImage = this.ImagePrefixString + _.clone(imageSmall);
      console.log(this.resultImage.substring(0, 40));
      console.log(this.resultImage.length);
      try {
        var dta = await dataloader.getImage(rawid);
        console.log("DONE " + rawid);
        console.log(dta.substring(0, 20));
        if (this.isLoading) {
          // the download may be cancelled in which case isLoading gets reset to false
          this.myImage = this.ImagePrefixString + _.clone(dta);
        }
      } catch (ex) {
        console.log(ex);
      }

      this.isLoading = false;
      console.log("Completed");
      //this.myImage = "/images/raw/"+imagename
      /*
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
var      base_image = new Image();
      base_image.src = "/images/raw/" + imagename;
      base_image.onload = function () {
          console.log("LOADED IMAGE")
        context.drawImage(base_image,100,100);
        console.log("LOADED IMAGE1")
        this.myImage = canvas.toDataURL("image/jpeg");

        console.log("LOADED IMAGE2")
        this.isLoading = false;
      };
      //this.myImage=await dataloader.getImage(imagename);
*/
      //this.isLoading = false;
    },

    addImageFromCamera() {
      this.captureType = "camera";
      this.$nextTick(() => {
        this.$refs.imagefile.click();
      });
    },
    addImageFromFile() {
      this.captureType = false;
      this.$nextTick(() => {
        this.$refs.imagefile.click();
      });
    },
    imageAction() {
      if (this.isSaving) return;
      if (this.myImage === "") this.$refs.imagefile.click();
      else this.showEditor(false);
    },
    uploadPhoto(evt) {
      console.log("UPLOAD PHOTO");
      console.log(evt);
      if (evt.target.files.length !== 0) {
        this.imgURLprevious = this.imgURL;
        this.imgURL = evt.target.files[0];
        this.rawImageIsNewPrevious = this.rawImageIsNew;
        this.rawImageIsNew = true;

        //if (this.imgURL) URL.revokeObjectURL(this.imgURL);
        //this.imgURL = window.URL.createObjectURL(e.target.files[0]);

        this.showEditor(true);
      }
    },
    setResultImage(canvas) {
      console.log("loading image");
      this.resultImage = canvas.toDataURL("image/jpeg", 1);
      console.log(this.resultImage.substring(0, 20));
      console.log(this.resultImage.length);
    },
    async showEditor(firstUpload) {
      var aMessage = [];
      var useParams = this.imageParams;
      if (firstUpload) {
        console.log("first upload");
        if (this.myImage) URL.revokeObjectURL(this.myImage);
        this.myImage = window.URL.createObjectURL(this.imgURL);
        useParams = {};
      }
      console.log("params " + JSON.stringify(useParams));
      var result = await this.$root.$imageeditfullscreen(
        this.myImage,
        "Image Edit",
        aMessage,
        {
          color: "secondary",
          reject: "",
          accept: "Okay",
          targetWidth: this.targetWidth,
          targetHeight: this.targetHeight,
          params: useParams,
        }
      );
      if (result.accept) {
        console.log(JSON.stringify(result.params));
        this.isLoading = false;
        // using a new image: mark raw image as new so it gets uploaded
        this.imgURLprevious = "";
        this.setResultImage(result.image);
        this.imageParams = JSON.parse(JSON.stringify(result.params));
      }
      if (result.accept === false) {
        console.log("cancel");
        if (this.imgURLprevious !== "") {
          this.imgURL = this.imgURLprevious;
          this.rawImageIsNew = this.rawImageIsNewPrevious;
          this.imgURLprevious = "";
          console.log(JSON.stringify(this.myImage));
          if (this.myImage) URL.revokeObjectURL(this.myImage);
          this.myImage = window.URL.createObjectURL(this.imgURL);
        }
      }
    },
  },
};
</script>
