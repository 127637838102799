//import store from "@/support/mainstore";
import dataloader from "@/api/dataloader";

const state = () => ({
    datalist: {},  // set by init. to be used for multiple views/pages, using  "name:list" pairs        
    datarefresh: 0,
    /**
     * because any changes are made inside the datalist structure, they don't trigger the reactions. the datarefresh counter
     * gets around that and is incremented each time datalist is changed, and it gets returned in the getter to trigger the update
     */
    busy: false,
})


const mutations = {
    setDataList(state, newlist, autoincrease = true) {
        //console.log("mutate " + newlist.name)
        state.datalist[newlist.name] = newlist.data;
        //console.log(autoincrease)
        if (autoincrease) state.datarefresh++;
    },
    refreshData(state) {
        //console.log("increment")
        state.datarefresh++;
    },
    setBusy(state, isBusy) {
        state.busy = isBusy
    },
}

const getters = {
    getDataList: (state) => (name) => {
        return { name: name, data: state.datalist[name], refresh: state.datarefresh }
    },
    isBusy: (state) => { return state.busy },
}

const actions = {
    async initialize(context) {
        console.log("initializing data...")        
        context.dispatch('refreshAll');
    },
    async refreshInfo(context, autoincrease = true) {
        context.commit('setDataList', {
            name: "info",
            data: await dataloader.getInfo()
        }, autoincrease)
    },
    
    async refreshConfig(context, autoincrease = true) {
        context.commit('setDataList', {
            name: "config",
            data: await dataloader.getConfig()
        }, autoincrease)
    },
    async refreshState(context, autoincrease = true) {
        context.commit('setDataList', {
            name: "state",
            data: await dataloader.getState()
        }, autoincrease)
    },
    async refreshSensors(context, autoincrease = true) {
        context.commit('setDataList', {
            name: "sensors",
            data: await dataloader.getData()
        }, autoincrease)
    },    
    async refreshEventLog(context, autoincrease = true) {
        context.commit('setDataList', {
            name: "events",
            data: await dataloader.getEvents()
        }, autoincrease)
    },
    async refreshAll(context) {
        console.log("refreshing all")
        await context.commit('setBusy', true)
        try {
            //await context.dispatch('refreshInfo', false);
            //await context.dispatch('refreshConfig', false);
            //await context.dispatch('refreshState', false);
            //await context.dispatch('refreshSensors', false);
            //await context.dispatch('refreshTools', false);
            //await context.dispatch('refreshEventLog');
        } catch (ex) {
            console.log("Refresh error")
            console.log(JSON.stringify(ex))
        }
        console.log("done refresh")
        await context.commit('setBusy', false)
    },
    async refreshAllIfNotBusy(context) {
        console.log(JSON.stringify(context))
    
        if (!context.getters.isBusy)
            return await context.dispatch('refreshAll')
        else
            console.log("skipping update because busy")
    }

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}