import store from "@/support/mainstore";

//import ViewStructures from "@/components/dataview/ViewStructure.js";

const state = () => ({
    menulist: [{ type: 'title', text: 'loading...' }],
    adminEditSpace: 0,
    adminEditEvent: 0,
    selecteditem: -1,
    drawer: null,
    enableToolEdit: false,
})

const mutations = {
    toggleDrawer(state) {
        console.log("Toggle Drawer")
        state.drawer = !state.drawer;
    },
    setMenuList(state, value) {
        state.menulist = JSON.parse(JSON.stringify(value))
    },
    setMenuIndex(state, value) {
        console.log("Menu Index")
        if (value !== undefined)
            state.selecteditem = value;
    },
    incrementState(state, target) {
        var targetEntry = state.menulist.find(el => (el.name === target));
        if (targetEntry !== undefined) {
            if (targetEntry.value + 1 >= targetEntry.states.length) {
                targetEntry.value = 0;
            } else {
                targetEntry.value++;
            }
            if (targetEntry.targetstate !== undefined) {
                // also set the target state to value
                state[targetEntry.targetstate] = targetEntry.value;
            }
        }
    },
    replaceBy(state, params) {
        console.log("replacing menu...")
        var targetName = params.name
        var targetGroup = params.group
        var targetObjectList = params.newlist
        // find match and modify target objects to include name or group
        if (targetName !== undefined) {
            targetObjectList[0].name = targetName
        }
        if (targetGroup !== undefined) {
            // if replacing a group with new content, mark the content with the group name, so that we can replace again if needed
            for (var k = 0; k < targetObjectList.length; k++)
                targetObjectList[k].group = targetGroup
        }
        var idx = 0;
        // go through menu and update anything that matches        
        // splice in the new list at the first match, and then remove any
        // subsequent matches (to help replace an entire group without duplicating)
        while (idx < state.menulist.length) {
            var matched = false;
            if (targetName !== undefined) {
                if (state.menulist[idx].name === targetName) {
                    matched = true
                }
            }
            if (targetGroup !== undefined) {
                if (state.menulist[idx].group === targetGroup) {
                    matched = true
                }
            }
            if (matched) {
                if (targetObjectList !== undefined) {
                    state.menulist.splice(idx, 1, ...targetObjectList)
                    idx += targetObjectList.length;
                    // only insert target once
                    targetObjectList = undefined
                } else {
                    state.menulist.splice(idx, 1)
                }
            } else {
                idx++
            }
        }
        console.log("done...")
    },
    amendBy(state, params) {
        var idx = 0;
        var targetName = params.name
        var targetGroup = params.group
        var targetObject = params.update
        var targetAll = (params.all == true)
        while (idx < state.menulist.length) {
            var matched = false;
            if (targetAll) {
                matched = true
            } else {
                if (targetName !== undefined) {
                    if (state.menulist[idx].name === targetName) {
                        matched = true
                    }
                }
                if (targetGroup !== undefined) {
                    if (state.menulist[idx].group === targetGroup) {
                        matched = true
                    }
                }
            }
            if (matched) {
                // use of splice triggers an update event
                state.menulist.splice(idx, 1, {
                    ...state.menulist[idx],
                    ...targetObject,
                })
            }
            idx++
        }
    },
}


function checkForAuthentication(entry, permissions) {
    var showThis = true;
    if (entry.auth !== undefined) {
        showThis = false;
        for (var m = 0; m < entry.auth.length; m++) {
            if (entry.auth[m].charAt(0) == '!') {
                showThis = showThis | (!permissions.includes(entry.auth[m].substring(1)));
            } else {
                showThis = showThis | permissions.includes(entry.auth[m]);
            }
        }
    }
    return showThis
}

const getters = {
    processMenuList(state, getters, rootState, rootGetters) {
        console.log("*** Get menu list")
        var result = [];
        var permissions = rootGetters['user/permissions'];
        var menulist = state.menulist;
        console.log("User auth = " + JSON.stringify(permissions))
        for (var k = 0; k < menulist.length; k++) {
            var entry = menulist[k];
            if (checkForAuthentication(entry, permissions))
                result.push(entry);
        }
        return result;
    },
    canEditTools: (state) => { return state.enableToolEdit },

    getIndexOfView: (state, getters, rootState, rootGetters) => (sublistName) => {
        var index = 0
        var permissions = rootGetters['user/permissions'];
        console.log("~~~ get index of view")
        for (const entry of state.menulist) {
            if ((entry.type === "link") && (checkForAuthentication(entry, permissions))) {
                //console.log(JSON.stringify(entry))
                if (entry.page.startsWith("/view/" + sublistName + "/")) {
                    //console.log(index)
                    return index
                }
                index = index + 1
            }
        }
        console.log("not found")
        return 0
    }
}

const actions = {
    processStateAction(context, params) {
        // trigger action commit on the target
        store.commit(params.commit, params.target)
        //console.log("switch to space")
        //store.dispatch('calendar/selectactivespace')
    },
    async initialize(context) {
        console.log("MENU STORE: initializaing menu...")        
        var ViewStructures = store.getters.getViewStructure
        context.commit('setMenuList', ViewStructures.defaultMenuList)
    },

    async setQuickLink(context, style) {
        console.log("MENU STORE: set menu to " + style)
        var ViewStructures = store.getters.getViewStructure
        // get the menu to use. can be a list, or a string to another list
        if (ViewStructures.quickLinks[style] === undefined) style = "default"
        var newlist = ViewStructures.quickLinks[style]
        if (typeof newlist === 'string') newlist = ViewStructures.quickLinks[newlist]
        const params = {
            newlist: newlist,
            group: "quicklinks",
        };
        context.commit('replaceBy', params);
    },


}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}


