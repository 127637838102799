<template>
  <v-dialog
    v-model="dialog"
    :max-width="MaxWidth"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    @keydown.left="prevKey"
    @keydown.right="nextKey"
    @click:outside="cancel"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-container>
        <v-row dense>
          <v-col>
            <ImageUpload :toolnameToShow="tool.Name" :auth="options.authObject" />
          </v-col>
        </v-row>

        <v-card-text v-if="!canEdit && !$vuetify.breakpoint.mdAndDown">
          Navigate the list using the buttons or left/right cursor keys.</v-card-text
        >

        <v-row>
          <v-col>
            <v-card-actions class="pt-0">
              <v-btn color="primary darken-1" text @click.native="agree">Close</v-btn>
              <v-spacer />
              <v-btn color="primary darken-1" text @click.native="prev">Prev</v-btn>
              <v-btn color="primary darken-1" text @click.native="next">Next</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageUpload from "@/components/dataview/ImageUpload";
import ProcessToolData from "@/support/processToolData.js";

/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  components: {
    ImageUpload,
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    tool: {},
    options: {},
    optionsDefaults: {
      color: "primary",
      width: 520,
      zIndex: 200,
      accept: "Yes",
      reject: "Cancel",
      authObject: {},
    },
    toolNameList: [],
    toolShowingIndex: 0,
  }),
  methods: {
    open(ToolList, CurrentIndex = 0, options = {}) {
      console.log("OPENING");
      var ToolName = ToolList[CurrentIndex];
      console.log("List " + JSON.stringify(ToolList));
      console.log("IDX " + JSON.stringify(CurrentIndex));
      console.log("Name " + JSON.stringify(ToolName));
      this.toolNameList = ToolList;
      this.toolShowingIndex = CurrentIndex;
      this.tool = this.AllTools[ToolName];
      this.options = JSON.parse(JSON.stringify(this.optionsDefaults));
      this.options = Object.assign(this.options, options);
      this.dialog = true;
      console.log("Tool " + JSON.stringify(this.tool));
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.dialog = false;
      this.resolve({ accept: true });
    },
    cancel() {
      this.dialog = false;
      this.resolve({ accept: false });
    },
    defaultView(tool) {
      console.log("View " + tool.DefaultView);
      if (tool.DefaultView !== undefined) return tool.DefaultView;
      return "";
    },
    saveModification(evt) {
      console.log(JSON.stringify(evt));
      console.log("save");
    },

    prevKey() {
      if (this.canEdit) return false;
      this.prev();
    },
    nextKey() {
      if (this.canEdit) return false;
      this.next();
    },
    prev() {
      if (this.toolShowingIndex <= 0) return;
      this.toolShowingIndex--;
      this.tool = this.AllTools[this.toolNameList[this.toolShowingIndex]];
    },
    next() {
      if (this.toolShowingIndex + 1 >= this.toolNameList.length) return;
      this.toolShowingIndex++;
      this.tool = this.AllTools[this.toolNameList[this.toolShowingIndex]];
    },
  },

  computed: {
    MaxWidth() {
      if (this.$vuetify.breakpoint.mdAndDown) return undefined;
      return this.options.width;
    },
    AllTools() {
      //console.log("AT "+JSON.stringify(this.$store.getters["tools/getTools"]))
      return this.$store.getters["tools/getTools"];
    },
    RawTools() {
      return this.$store.getters["tools/getRawTools"];
    },
    ToolsToDisplay() {
      if (this.ToolViewList === undefined) return {};
      var AllToolsToUse = this.AllTools;
      if (Object.keys(this.InjectedTools).length !== 0) {
        AllToolsToUse = JSON.parse(JSON.stringify(this.InjectedTools)); // needed to avoid infinite loop...
      }
      return ProcessToolData.PopulateToolViewList(this.ToolViewList, AllToolsToUse);
    },
    canEdit() {
      if (
        this.options.authObject === undefined ||
        this.options.authObject.editEnable === undefined
      )
        return false;
      return this.options.authObject.editEnable;
    },
  },
};
</script>
