import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'
import ColorTheme from '@/support/colortheme';

Vue.use(Vuetify);

var presetNew = preset
presetNew.theme.options = {
  customProperties: true
}

export default new Vuetify(
  ColorTheme.update(presetNew)
);
