/**
 * View structures. This is the default view, which can be custumized via API (or user specific config)
 * 
 */

const toolViews = {
    Default: {
        Name: "Default tool view",
        Description: "The default tool page, with all the relevant information",
        Header: { source: "Label", canedit: 'text' },
        Image: { height: 200, gradient: "" },
        Subheader: { source: "Details.OneLine", canedit: 'text' },
        Icon: "mdi-arrow-expand",
        Sections: [
            {
                Collapsible: false,
                Name: false,
                Entries: [
                    {
                        Icon: "mdi-card-text-outline", Title: "Equipment",
                        Contents: [{ name: "Model", source: "Specs.Model", canedit: 'text', undef: '' }, { name: "Location", source: "Specs.Location", canedit: 'text' }, { name: "Staff", source: "Specs.Staff", filter: "makeStaffList" }, { name: "MIT internal rate (1x)", source: "Specs.Fees.MIT", filter: "puToUSD" }]
                    },
                    {
                        Icon: "mdi-magnify", Title: "Technical Specs", canedit: 'text', Contents: [
                            { name: "Category", source: "Specs.Category", filter: "makeCategoryList" },
                            { name: "Features", source: "Specs.Features", filter: "makeFeaturesList" },
                            { name: "Gases", source: "Specs.Gases", filter: "makeGasList" },
                            { name: "Materials", source: "Specs.Materials", filter: "makeRateList" },
                        ], Editor: { Icon: "mdi-check", Modal: "Something" },
                    },
                    { Icon: "mdi-information-variant", Text: { source: "Details.Summary", canedit: 'text' } },
                ]
            },
            {
                Collapsible: true,
                Name: "Details",
                Entries: [
                    { Icon: undefined, Name: undefined, Text: { source: "Details.Narrative", canedit: 'text' } },
                    { Icon: "mdi-heart-outline", Title: "Best for", Text: { source: "Details.BestFor", canedit: 'text' } },
                    { Icon: "mdi-cancel", Title: "Limitations", Text: { source: "Details.Limitations", canedit: 'text' } },
                    { Icon: "mdi-alert-outline", Title: "Caution with", Text: { source: "Details.CautionWith", canedit: 'text' } },
                    { Icon: "mdi-shuffle-variant", Title: "Closest Alternatives", List: { source: "Details.Alternatives", filter: "makeAlternativeList" } },
                ]
            },
            {
                Collapsible: true,
                SkipFor: ['Group'],
                Name: "Materials",
                Entries: [
                    { Icon: "mdi-check", Title: "Accepted Materials", canedit: 'text', List: { source: "Materials.Accepted", filter: "makeMaterialsList" } },
                    { Icon: "mdi-cancel", Title: "Prohibited Materials", canedit: 'text', List: { source: "Materials.Prohibited", filter: "makeMaterialsList" } },
                    { Icon: "mdi-alert-octagon-outline", Title: "Substrate Restrictions", canedit: 'text', List: { source: "Materials.Substrate", filter: "makeMaterialsList" } },
                ]
            },
            {
                Collapsible: true,
                Name: "Documents",
                FromList: { source: "Documents", filter: "makeDocumentList" },
            }
        ]
    },
    FullWidthHeader: {
        Name: "Full width header",
        Description: "Show nothing but the label",
        Header: { source: "Label", canedit: 'text' },
        //Subheader: { source: "Details.OneLine", canedit: 'text' },        
        Icon: "mdi-arrow-expand",
        Sections: [
            {
                Collapsible: false,
                Name: false,
                Entries: [
                    {
                        Icon: "mdi-card-text-outline", Title: "Equipment",
                        Contents: [
                            { name: "Model", source: "Specs.Model", canedit: 'text', undef: '', ViewTags: ["-groupoverview"] },
                            { name: "Location", source: "Specs.Location", canedit: 'text' },
                            { name: "Staff", source: "Specs.Staff", filter: "makeStaffList" },
                            { name: "MIT internal rate (1x)", source: "Specs.Fees.MIT", filter: "puToUSD" }
                        ],
                        ViewTags: ["default", "groupoverview"]
                    },
                    {
                        Icon: "mdi-magnify", Title: "Technical Specs", canedit: 'text',
                        Contents: [
                            { name: "Features", source: "Specs.Features", filter: "makeFeaturesList" },
                            { name: "Gases", source: "Specs.Gases", filter: "makeGasList" },
                        ],
                        Editor: { Icon: "mdi-check", Modal: "Something" },
                    },
                    {
                        Icon: "mdi-information-variant",
                        Text: { source: "Details.Summary", canedit: 'text' },
                        ViewTags: ["default", "-groupoverview"]  //  used if "ViewTags" from the tool matches (or if undefined, then is shown by default). viewtags are inverted (show if) with -
                    },
                    {
                        Icon: "mdi-information",
                        Text: { source: "Details.Summary", canedit: 'text' },
                        ViewTags: ["-default", "groupoverview"]  //  used if "ViewTags" from the tool matches (or if undefined, then is shown by default). viewtags are inverted (show if) with -
                    },
                ]
            }
        ]
    },
    GroupView: {
        Name: "Detailed Group View",
        Description: "Expanded group view with info but no photo",
        Header: { source: "Label", canedit: 'text' },
        Subheader: { source: "Details.OneLine", canedit: 'text' },
        Icon: "mdi-arrow-expand",
        Sections: [
            {
                Collapsible: false,
                Name: false,
                Entries: [
                    {
                        Icon: "mdi-card-text-outline", Title: "Equipment",
                        Contents: [{ name: "Model", source: "Specs.Model", canedit: 'text', undef: '' }, { name: "Location", source: "Specs.Location", canedit: 'text' }, { name: "Staff", source: "Specs.Staff", filter: "makeStaffList" }, { name: "MIT internal rate (1x)", source: "Specs.Fees.MIT", filter: "puToUSD" }]
                    },
                    {
                        Icon: "mdi-magnify", Title: "Technical Specs", canedit: 'text', Contents: [
                            { name: "Category", source: "Specs.Category", filter: "makeCategoryList" },
                            { name: "Features", source: "Specs.Features", filter: "makeFeaturesList" },
                            { name: "Gases", source: "Specs.Gases", filter: "makeGasList" },
                        ], Editor: { Icon: "mdi-check", Modal: "Something" },
                    },
                    { Icon: "mdi-information-variant", Text: { source: "Details.Summary", canedit: 'text' } },
                ]
            },
            {
                Collapsible: true,
                Name: "Details",
                Entries: [
                    { Icon: undefined, Name: undefined, Text: { source: "Details.Narrative", canedit: 'text' } },
                    { Icon: "mdi-alert-outline", Title: "Caution with", Text: { source: "Details.CautionWith", canedit: 'text' } },
                ]
            },
            {
                Collapsible: true,
                Name: "Documents",
                FromList: { source: "Documents", filter: "makeDocumentList" },
            }
        ]
    },
    FAQ: {
        Name: "Default view for FAQs",
        Description: "FAQ and other information",
        Header: { source: "Label", canedit: 'text' },
        Image: { height: 200, source: "ImageName", gradient: "to top, rgba(192,191,192,.5), rgba(227,242,253,.9)" },
        Subheader: { source: "Details.OneLine", canedit: 'text' },
        Icon: "mdi-arrow-expand",
        Build: {
            source: "Details.Build",
            // build generates a list of sections and/or entries. it looks up the Type here (allowing us to customize the views of questions vs information sections etc)
            // external links and documents (via FromList makeDocumentList) can also be included.
            // content can be generated to respond to buttons using the IfSet & IfNotSet fields
            Question: {
                Collapsible: { source: "Collapsible" },
                Name: { source: "Headline", canedit: 'text' },
                ShowIf: { source: "ShowIf" },
                Build: { Icon: { source: "Icon", default: "mdi-check" }, Image: { source: "Image", default: {} }, Title: { source: "Question", canedit: 'text' }, Text: { source: "Answer", default: "no answer has been submitted yet", canedit: 'text' }, ShowIf: { source: "ShowIf" }, Link: { source: "Link" } },
            },
            People: {
                Collapsible: { source: "Collapsible" },
                Name: { source: "Headline", canedit: 'text' },
                ShowIf: { source: "ShowIf" },
                Build: { Image: { source: "Image", default: {}, isRound: true }, Title: { source: "FullName", canedit: 'text' }, MultiText: [{ prefix: "Office:", source: "Details.Office" }, { prefix: "Phone:", source: "Details.Phone" }, { prefix: "Email:", source: "Details.Email", isEmail: true }] },
            },
            Choice: {
                Collapsible: false,
                Name: { source: "Title", canedit: 'text' },
                Buttons: {
                    Icon: { source: "Icon" },
                    Title: { source: "Label", canedit: 'text' }
                },
            }
        }
    },
    FAQNoImage: {
        Name: "Default view for FAQs",
        Description: "FAQ and other information",
        Header: { source: "Label", canedit: 'text' },
        Subheader: { source: "Details.OneLine", canedit: 'text' },
        Icon: "mdi-arrow-expand",
        Build: {
            source: "Details.Build",
            // build generates a list of sections and/or entries. it looks up the Type here (allowing us to customize the views of questions vs information sections etc)
            // external links and documents (via FromList makeDocumentList) can also be included.
            // content can be generated to respond to buttons using the IfSet & IfNotSet fields
            Question: {
                Collapsible: { source: "Collapsible" },
                Name: { source: "Headline", canedit: 'text' },
                ShowIf: { source: "ShowIf" },
                Build: { Icon: { source: "Icon", default: "mdi-check" }, Image: { source: "Image", default: {} }, Title: { source: "Question", canedit: 'text' }, Text: { source: "Answer", default: "no answer has been submitted yet", canedit: 'text' }, ShowIf: { source: "ShowIf" }, Link: { source: "Link" } },
            },
            People: {
                Collapsible: { source: "Collapsible" },
                Name: { source: "Headline", canedit: 'text' },
                ShowIf: { source: "ShowIf" },
                Build: { Image: { source: "Image", default: {}, isRound: true }, Title: { source: "FullName", canedit: 'text' }, MultiText: [{ prefix: "Office:", source: "Details.Office" }, { prefix: "Phone:", source: "Details.Phone" }, { prefix: "Email:", source: "Details.Email", isEmail: true }] },
            },
            Choice: {
                Collapsible: false,
                Name: { source: "Title", canedit: 'text' },
                Buttons: {
                    Icon: { source: "Icon" },
                    Title: { source: "Label", canedit: 'text' }
                },
            }
        }
    },
    Pictures: {
        Name: "Photo tool view",
        Description: "A simple photo & summary only tool view",
        Header: { source: "Label", canedit: 'text' },
        Image: { height: 200 },
        Subheader: { source: "Details.OneLine", canedit: 'text' },
        Icon: "mdi-arrow-collapse",
        Sections: [
        ],
    },
    StaffViewWithDetails: {
        Name: "Staff view",
        Description: "Staff contact information",
        Header: { source: "Label", canedit: 'text' },
        Image: { height: 200 },
        Subheader: { source: "StaffDetails.Area", canedit: 'text' },
        Icon: "mdi-arrow-collapse",
        PreventExpansion: true,
        Sections: [
            {
                Collapsible: false,
                Name: "Contact",
                Entries: [
                    { Icon: "mdi-map-marker-outline", Name: undefined, Title: "Office", Text: { source: "StaffDetails.Office", canedit: 'text' } },
                    { Icon: "mdi-email-outline", Name: undefined, Title: "Email", Text: { source: "StaffDetails.Email", canedit: 'text', isEmail: true } },
                    { Icon: "mdi-phone-outline", Name: undefined, Title: "Phone", Text: { source: "StaffDetails.Phone", canedit: 'text' } },
                ]
            }
        ],
    },
    StaffView: {
        Name: "Staff view",
        Description: "Staff contact information",
        Header: { source: "Label", canedit: 'text' },
        Image: { height: 200 },
        Subheader: { source: "StaffDetails.Area", canedit: 'text' },
        Icon: "mdi-arrow-expand",
        PreventExpansion: true,
        Sections: [
        ],
    },
    StaffCoverage: {
        Name: "Staff evening coverage",
        Description: "Staff evening coverage information",
        Header: { source: "Label", canedit: 'text' },
        Subheader: { source: "Details.OneLine", canedit: 'text' },
        Icon: "mdi-arrow-collapse",
        PreventExpansion: true,
        Sections: [
            {
                Collapsible: true,
                Name: "Evening Coverage / Long Day",
                Entries: [
                    { Icon: "mdi-alpha-m-circle-outline", Name: undefined, Title: "Monday", Text: { build: { source: "StaffDetails.LateDay", filterparams: { value: ["Mon"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-t-circle-outline", Name: undefined, Title: "Tuesday", Text: { build: { source: "StaffDetails.LateDay", filterparams: { value: ["Tue"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-w-circle-outline", Name: undefined, Title: "Wednesday", Text: { build: { source: "StaffDetails.LateDay", filterparams: { value: ["Wed"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-r-circle-outline", Name: undefined, Title: "Thursday", Text: { build: { source: "StaffDetails.LateDay", filterparams: { value: ["Thu"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-f-circle-outline", Name: undefined, Title: "Friday", Text: { build: { source: "StaffDetails.LateDay", filterparams: { value: ["Fri"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                ]
            },
            {
                Collapsible: true,
                Name: "Day Off",
                Entries: [
                    { Icon: "mdi-alpha-m-circle-outline", Name: undefined, Title: "Monday", Text: { build: { source: "StaffDetails.DayOff", filterparams: { value: ["Mon"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-t-circle-outline", Name: undefined, Title: "Tuesday", Text: { build: { source: "StaffDetails.DayOff", filterparams: { value: ["Tue"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-w-circle-outline", Name: undefined, Title: "Wednesday", Text: { build: { source: "StaffDetails.DayOff", filterparams: { value: ["Wed"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-r-circle-outline", Name: undefined, Title: "Thursday", Text: { build: { source: "StaffDetails.DayOff", filterparams: { value: ["Thu"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                    { Icon: "mdi-alpha-f-circle-outline", Name: undefined, Title: "Friday", Text: { build: { source: "StaffDetails.DayOff", filterparams: { value: ["Fri"] }, includeif: "contains" }, source: "Label", filter: "concatArray", } },
                ]
            }
        ],
    },

}

// tool view sequence is the card cycle of views with name:icon to show (so that icons can point at the next action)
// the default view is the 1sth entry
const viewSequence = {
    Default: [{ name: "Pictures", icon: "mdi-arrow-collapse" }  /** , { name: "Default", icon: "mdi-arrow-expand" }**/],
    Group: [{ name: "Pictures", icon: "mdi-arrow-collapse" }, { name: "GroupView", icon: "mdi-arrow-expand" }],
    Tool: [{ name: "Pictures", icon: "mdi-arrow-collapse", useModalTarget: "ToolDetails" } /**, { name: "Default", icon: "mdi-arrow-expand" }*/],
    ToolDetails: [{ name: "Default", icon: "mdi-arrow-collapse" }],
    ObjectEditor: [{ name: "Default", icon: "mdi-arrow-expand" }, { name: "Pictures", icon: "mdi-arrow-collapse" }],
    FullWidthHeader: [{ name: "FullWidthHeader", icon: "mdi-arrow-collapse" }],
    StaffView: [{ name: "StaffView", icon: "mdi-arrow-collapse" }, { name: "StaffViewWithDetails", icon: "mdi-arrow-expand" }],
    StaffCoverage: [{ name: "StaffCoverage", icon: "mdi-arrow-collapse" }],
    FAQ: [{ name: "Pictures", icon: "mdi-arrow-collapse" }, { name: "FAQ", icon: "mdi-arrow-expand" }],
    FullWidthFAQ: [{ name: "Pictures", icon: "mdi-arrow-collapse" }, { name: "FAQ", icon: "mdi-arrow-expand" }],
}

const tableViews = {
    Default: {
        Label: "Full View",
        LinkName: "tablefull",
        cantHave: ["staff"],
        Columns: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
                source: "Name",
            },
            { text: "Model", value: "Model", source: "Specs.Model" },
            { text: "Location", value: "Location", source: "Specs.Location" },
            {
                text: "Category",
                value: "Category",
                source: { source: "Specs.Category", filter: "makeCategoryList" },
            },

            { text: "Details", value: "Details", source: "Details.OneLine" },
            {
                text: "MIT Internal Rate (1x)",
                value: "Rate",
                source: { source: "Specs.Fees.MIT", filter: "puToUSD" },
            },
            { text: "Status", value: "Status", source: "StatusText" },
        ],
    },
    Models: {
        Label: "Model Numbers",
        LinkName: "tablemodels",
        cantHave: ["staff"],
        Columns: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
                source: "Name",
            },
            { text: "Model", value: "Model", source: "Specs.Model" },
            /*            {
                            text: "Install Age (Years)",
                            value: "Age",
                            source: { source: "Specs.FirstUse", filter: "dateToYears" },
                        },
            */
            { text: "One-Line", value: "Details", source: "Details.OneLine" },
            { text: "Primary Staff", value: "Staff", source: { source: "Specs.Staff.Primary", filter: "makeStaffListSingleType" } },
            { text: "Backup", value: "Backup", source: { source: "Specs.Staff.Backup", filter: "makeStaffListSingleType" } },
        ],
    },
    Details: {
        Label: "Tool Descriptions & Staff",
        LinkName: "tabledescriptions",
        cantHave: ["staff"],
        Columns: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
                source: "Name",
            },
            { text: "Details", value: "Details", source: "Details.OneLine" },
            { text: "Primary Staff", value: "Staff", source: { source: "Specs.Staff.Primary", filter: "makeStaffListSingleType" } },
        ],
    },
    Etchers: {
        Label: "Etchers",
        LinkName: "tableetchers",
        Contents: ["EtchGroup"],
        cantHave: ["char", "staff"],
        Columns: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
                source: "Name",
            },
            { text: "Details", value: "Details", source: "Details.OneLine" },
            {
                text: "Process Gases",
                value: "Gases",
                source: { source: "Specs.Gases", filter: "makeGasListShort" },
            },
            {
                text: "Materials",
                value: "Materials",
                source: { source: "Specs.Materials", filter: "makeRateList" },
            },
        ],
    },

    ProblemShutdowns: {
        Label: "Problems and Shutdowns",
        LinkName: "tableps",
        Contents: ["ProblemBuildGroup", "DownBuildGroup"],
        cantHave: ["char", "staff"],
        Columns: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
                source: "Name",
            },
            { text: "Location", value: "Location", source: "Specs.Location" },
            {
                text: "Category",
                value: "Category",
                source: { source: "Specs.Category", filter: "makeCategoryList" },
            },
            { text: "Tool Details", value: "Details", source: "Details.OneLine" },
            { text: "Status", value: "Status", source: "StatusText" },
            { text: "Days Since", value: "DayCount", source: { source: "StatusDate", filter: "makeDayCount" } },
            { text: "Date", value: "Date", source: { source: "StatusDate", filter: "makeDateTime" } },
            { text: "Reason", value: "Reason", source: "StatusReason" },

        ],
    },
    Rates: {
        Label: "Tool Rates",
        LinkName: "tablerates",
        cantHave: ["staff"],
        Columns: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
                source: "Name",
            },
            {
                text: "Category",
                value: "Category",
                source: { source: "Specs.Category", filter: "makeCategoryList" },
            },
            { text: "Model", value: "Model", source: "Specs.Model" },
            {
                text: "MIT Internal Rate (1x)",
                value: "Rate",
                source: { source: "Specs.Fees.MIT", filter: "puToUSD" },
            }
        ],
    },
    Staff: {
        Label: "Staff Directory",
        LinkName: "tablestaff",
        Contents: ["StaffGroup"],
        mustHave: ["staff"],
        hideChildren: true,
        includeBuild: true,
        filterBy: { mustHave: { Special: "Staff" }, cantHave: {} },
        Columns: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "Name",
                source: "Label",
            },
            { text: "Area", value: "Area", source: "StaffDetails.Area" },
            { text: "Office", value: "Office", source: "StaffDetails.Office" },
            { text: "Email", value: "Email", source: "StaffDetails.Email", isEmail: true },
            { text: "Phone", value: "Phone", source: "StaffDetails.Phone" },
        ],
    },

}

const quickLinkViews = {
    help: [
        {
            type: "link",
            icon: "mdi-lightbulb-question-outline",
            text: "Process Help",
            page: "/view/newprocess",
        },
        {
            type: "link",
            icon: "mdi-contacts-outline",
            text: "Technical Staff",
            page: "/view/staff/table",
        },
        {
            type: "link",
            icon: "mdi-clock-outline",
            text: "Lab Hours",
            page: "/view/hours/one",
        },
        /*{
            type: "link",
            icon: "mdi-binoculars",
            text: "Tool Overview",
            page: "/",
        },*/
    ],
    fabcategories: [
        {
            type: "link",
            icon: "mdi-download-multiple",
            text: "Deposition",
            page: "/view/dep/one",
        },
        {
            type: "link",
            icon: "mdi-camera-outline",
            text: "Lithography",
            page: "/view/litho/one",
        },
        {
            type: "link",
            icon: "mdi-tray-arrow-up",
            text: "Dry Etch",
            page: "/view/etch/one",
        },
        {
            type: "link",
            icon: "mdi-bullseye-arrow",
            text: "Metrology",
            page: "/view/metrology/one",
        },
        {
            type: "link",
            icon: "mdi-flask",
            text: "Wet Etch & Clean",
            page: "/view/wet/one",
        },
        {
            type: "link",
            icon: "mdi-thermometer-plus",
            text: "Diffusion",
            page: "/view/thermal/one",
        },
        {
            type: "link",
            icon: "mdi-package-variant",
            text: "Packaging",
            page: "/view/packaging/one",
        },
        {
            type: "link",
            icon: "mdi-cup-water",
            text: "Soft-Litho",
            page: "/view/softlitho/one",
        },
    ],

    charcategories: [
        {
            type: "link",
            icon: "mdi-download",
            text: "Scanning EM",
            page: "/view/sem/one",
        },
        {
            type: "link",
            icon: "mdi-snowflake",
            text: "Cryo EM",
            page: "/view/cryo/one",
        },
        {
            type: "link",
            icon: "mdi-image-filter-hdr",
            text: "Surface Analysis",
            page: "/view/surf/one",
        },
        {
            type: "link",
            icon: "mdi-bullseye-arrow",
            text: "Cleanroom Metrology",
            page: "/view/crmetrology/one",
        },
    ],

    faq: "help",

    char: "charcategories",
    sem: "charcategories",
    cryo: "charcategories",
    surf: "charcategories",
    crmetrology: "charcategories",

    fab: "fabcategories",
    dep: "fabcategories",
    litho: "fabcategories",
    etch: "fabcategories",
    wet: "fabcategories",
    thermal: "fabcategories",
    packaging: "fabcategories",
    softlitho: "fabcategories",
    metrology: "fabcategories",
    fabshutdown: "fabcategories",
    location: "help",
    login: "help",
    newprocess: "help",
    default: "help",
    hours: "help",
}

const ViewSublists = {
    mainpage: { tools: ["MainPageGroup"], defaultview: "Pictures" },
    faq: { tools: ["FAQNewUser"], defaultview: "Default" },
    newprocess: { tools: ["FAQProcess", "FAQSampleProcesses"], defaultview: "Default" },

    location: { tools: ["LocationTopLevel"], defaultview: "Pictures" },
    hours: { tools: ["HoursGroup"], defaultview: "Pictures" },

    coraltree: { tools: ["CoralTreeGroup"], defaultview: "Pictures" },

    nano: { tools: ["AllNanoGroup"], defaultview: "Pictures" },
    fab: { tools: ["FabNanoGroup"], defaultview: "Pictures" },
    char: { tools: ["CharacterizationNanoGroup"], defaultview: "Pictures" },

    dep: { tools: ["DepositionGroup"], defaultview: "Pictures" },
    litho: { tools: ["LithographyGroup"], defaultview: "Pictures" },
    etch: { tools: ["EtchGroup"], defaultview: "Pictures" },
    metrology: { tools: ["MetrologyGroup"], defaultview: "Pictures" },
    wet: { tools: ["BenchesGroup"], defaultview: "Pictures" },
    thermal: { tools: ["DiffusionGroup"], defaultview: "Pictures" },
    packaging: { tools: ["PackagingBuildGroup"], defaultview: "Pictures" },
    softlitho: { tools: ["SoftLithoGroup"], defaultview: "Pictures" },

    sem: { tools: ["CharSEMGroup"], defaultview: "Pictures" },
    cryo: { tools: ["CharCryoGroup"], defaultview: "Pictures" },
    surf: { tools: ["CharSurfGroup"], defaultview: "Pictures" },
    crmetrology: { tools: ["MetrologyGroup"], defaultview: "Pictures" },

    staff: { tools: ["StaffGroup"], defaultview: "StaffView", depth: 1 },

    fabshutdown: { tools: ["DownBuildGroup"], defaultview: "Pictures" },
}

var DefaultMenuList = [
    {
        type: 'title',
        text: 'Pages & Areas',
        name: 'header',
    },
    {
        type: 'link',
        icon: 'mdi-home',
        text: 'Home & Get Started',
        page: '/',
    },
    {
        type: 'link',
        icon: 'mdi-wrench',
        text: 'Fab Equipment',
        page: '/view/fab/table',
    },
    {
        type: 'link',
        icon: 'mdi-microscope',
        text: 'Characterization',
        page: '/view/char/table',
    },
    {
        type: 'link',
        icon: 'mdi-map-search',
        text: 'Locations',
        page: '/view/location/one',
    },
    {
        type: 'link',
        icon: 'mdi-shape-outline',
        text: 'Tools By Category',
        page: '/view/coraltree/one',
    },
    {
        type: 'link',
        icon: 'mdi-palette',
        text: 'Theme',
        page: '/theme',
        auth: ['edit-code'],
    },
    
    /*{
        type: 'link',
        icon: 'mdi-help-circle-outline',
        text: 'Help',
        page: '/view/faq',
    },
    {
        type: 'link',
        icon: 'mdi-login',
        text: 'Log in or Register',
        page: '/login',
        auth: ['!view'],
    },*/
    {
        type: 'link',
        icon: 'mdi-calendar-check',
        text: 'All My Bookings',
        page: '/calendar',
        preaction: { call: 'calendar/setactivespace', showall: true },
        auth: ['viewno'],
    },
    {
        type: 'divider',
    },
    {
        type: 'title',
        text: 'My Quick Views',
        auth: ['view'],
    },
    {
        type: 'title',
        text: 'Image & Quick Views',
        auth: ['!view'],
    },
    {
        type: 'placeholder',
        group: 'quicklinks',
    },
    {
        type: 'divider',
        auth: ['edit-tools','edit-photos','edit-code'],
    },
    {
        type: 'title',
        text: 'Staff Actions',
        auth: ['edit-tools','edit-photos','edit-code'],
    },

    {
        /******************************
         * The toggle can switch icon & text when clicked, and can be used as a quick
         * configuration option. the actions are state commits to increase the value
         * but a separate targetstate can also be specified, which is how others can
         * listen to the selection and react to the toggle.
         ********************/
        type: 'toggle',
        value: 0,
        targetstate: 'enableToolEdit',
        states: [
            { icon: 'mdi-database-eye-outline', iconcolor: 'white', text: 'Read-Only' },
            { icon: 'mdi-database-edit-outline', iconcolor: 'green lighten-2', text: 'Edit Tool Details' },                    
        ],
        action: { commit: 'menu/incrementState', target: 'tools-edit-enabled' },
        auth: ['edit-tools','edit-photos'],
        name: 'tools-edit-enabled',
    },
    {
        type: 'link',
        icon: 'mdi-plus',
        text: 'Code Editor',
        page: '/edit',
        auth: ['edit-code'],
    },
    {
        type: 'link',
        icon: 'mdi-database-import-outline',
        text: 'Bulk Upload',
        page: '/upload',
        auth: ['edit-code'],
    },
    {
        type: 'link',
        icon: 'mdi-camera-outline',
        text: 'Add Photo',
        page: '/add/image',
        auth: ['edit-photos'],
    },
    {
        type: 'link',
        icon: 'mdi-plus',
        text: 'View Editor',
        page: '/editviews',
        auth: ['edit-code'],
    },
    /*{
        type: 'link',
        icon: 'mdi-image-outline',
        text: 'Photo Editor',
        page: '/edit/images',
        auth: ['view'],
    },
    {
        type: 'modal',
        icon: 'mdi-lightbulb',
        text: 'Help..',
        modal: 'showSomeData',
        options: { text: "test" },
        auth: ['view'],
    },*/
]

var baseSet = { defaultMenuList: DefaultMenuList, toolViews: toolViews, toolViewSequence: viewSequence, tableViews: tableViews, quickLinks: quickLinkViews, viewSublists: ViewSublists }

module.exports = baseSet
