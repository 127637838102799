<!-------------------------------------------------------------

MIT.nano Main app
This is just a skeleton to include
the structure with top and side bar
as well as main paged.

Main page is handled via router and parameters
while side bars and top bar react to $store values

-------------------------------------------------------------->

<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <!--- Core modal components --->
    <ConfirmDialog ref="confirmdialog" />
    <JSONEditorDialog ref="jsoneditordialog" />
    <OneToolDialog ref="onetooldialog" />
    <OnePhotoDialog ref="onephotodialog" />
    <ImageEditFullScreen ref="imageeditfullscreen" />
    <!--- Main App --->
    <TopBar />
    <SideBar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/navigation/TopBar";
import SideBar from "./components/navigation/SideBar";
import ConfirmDialog from "@/support/modals/ConfirmDialog";
import JSONEditorDialog from "@/support/modals/JSONEditor";
import OneToolDialog from "@/support/modals/OneToolDialog";
import OnePhotoDialog from "@/support/modals/OnePhotoDialog";
import ImageEditFullScreen from "@/support/modals/ImageEditFullScreen";

export default {
  name: "App",
  components: {
    TopBar,
    SideBar,
    ConfirmDialog,
    JSONEditorDialog,
    OneToolDialog,
    OnePhotoDialog,
    ImageEditFullScreen,
  },
  mounted() {
    console.log("mounting components...");
    this.$root.$confirmdialog = this.$refs.confirmdialog.open;
    this.$root.$jsoneditordialog = this.$refs.jsoneditordialog.open;
    this.$root.$onetooldialog = this.$refs.onetooldialog.open;
    this.$root.$onephotodialog = this.$refs.onephotodialog.open;
    this.$root.$imageeditfullscreen = this.$refs.imageeditfullscreen.open;
    console.log("mounting components done");
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<!--- make sure Z index for sidebar is sufficiently above content, but below the modal dialogs --->
<style>
.v-navigation-drawer {
  z-index: 100 !important;
}
</style>
