<template>
  <v-dialog
    v-model="dialog"
    :max-width="MaxWidth"
    :width="MaxWidth"
    :overlay-color="getTheme('onetooloverlay')"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    @keydown.left="prevKey"
    @keydown.right="nextKey"
    @click:outside="cancel"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :hide-overlay="$vuetify.breakpoint.smAndDown"
  >
    <v-card :class="MobileClass" :color="getTheme('onetool')">
      <v-container :class="MobileClass">
        <v-row
          :class="MobileClass"
          v-touch="{
            left: () => nextKey(),
            right: () => prevKey(),
          }"
        >
          <v-col :class="MobileClass">
            <ToolCardView
              :toolname="tool.Name"
              :AllToolList="[tool]"
              :RawToolList="RawTools"
              :CtrToolToShow="tool._ctr"
              :DefaultViewmode="defaultView(tool)"
              :auth="options.authObject"
              :FillWidth="$vuetify.breakpoint.smAndDown"
              :AddCloseButton="$vuetify.breakpoint.smAndDown"
              @saveModification="saveModification"
              @modalPhotoView="modalPhotoRequest(tool, $event)"
              @closeRequest="cancel"
            />
            {{ defaultView(tool) }}
          </v-col>
        </v-row>

        <v-card-text
          v-if="!canEdit && !$vuetify.breakpoint.smAndDown && toolNameList.length > 1"
          :class="MobileClass + ' cardtext--text'"
        >
          Navigate the list using the buttons or left/right cursor keys.</v-card-text
        >
        <v-row :class="MobileClass" v-if="!$vuetify.breakpoint.smAndDown">
          <v-col>
            <v-card-actions>
              <v-btn color="primary darken-1" text @click.native="agree">Close</v-btn>
              <v-spacer />
              <v-btn
                color="primary darken-1"
                text
                @click.native="prev"
                v-if="toolNameList.length > 1"
                >Prev</v-btn
              >
              <v-btn
                color="primary darken-1"
                text
                @click.native="next"
                v-if="toolNameList.length > 1"
                >Next</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ToolCardView from "@/components/dataview/ToolCardView";
import ProcessToolData from "@/support/processToolData.js";
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  components: {
    ToolCardView,
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    tool: {},
    options: {},
    optionsDefaults: {
      color: "primary",
      width: 520,
      zIndex: 200,
      accept: "Yes",
      reject: "Cancel",
      authObject: {},
    },
    toolNameList: [],
    toolShowingIndex: 0,
  }),

  methods: {
    getTheme(fieldname) {
      var entry = this.$vuetify.theme.themes[this.theme];
      if (entry == undefined) return undefined;
      return entry[fieldname];
    },

    open(ToolList, CurrentIndex = 0, options = {}) {
      var ToolName = ToolList[CurrentIndex];
      //console.log("List " + JSON.stringify(ToolList));
      //console.log("IDX " + JSON.stringify(CurrentIndex));
      //console.log("Name " + JSON.stringify(ToolName));
      this.toolNameList = ToolList;
      this.toolShowingIndex = CurrentIndex;
      //console.log(JSON.stringify(this.AllTools));
      this.tool = this.AllTools[ToolName];
      this.options = JSON.parse(JSON.stringify(this.optionsDefaults));
      this.options = Object.assign(this.options, options);
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.dialog = false;
      this.resolve({ accept: true });
    },
    cancel() {
      this.dialog = false;
      this.resolve({ accept: false });
    },
    defaultView(tool) {
      if (this.options.UseView) return this.options.UseView;
      if (tool.DefaultView !== undefined) return tool.DefaultView;
      return "";
    },
    saveModification(evt) {
      console.log(JSON.stringify(evt));
      console.log("save");
    },
    modalPhotoView(evt) {
      console.log(JSON.stringify(evt));
      console.log("edit photo");
    },

    prevKey() {
      if (this.canEdit) return false;
      this.prev();
    },
    nextKey() {
      if (this.canEdit) return false;
      this.next();
    },
    prev() {
      if (this.toolShowingIndex <= 0) return;
      this.toolShowingIndex--;
      this.tool = this.AllTools[this.toolNameList[this.toolShowingIndex]];
    },
    next() {
      if (this.toolShowingIndex + 1 >= this.toolNameList.length) return;
      this.toolShowingIndex++;
      this.tool = this.AllTools[this.toolNameList[this.toolShowingIndex]];
    },
    async modalPhotoRequest(tool, evt) {
      //console.log(evt._state.ToolName);
      //console.log(JSON.stringify(this.toolNameList))
      var tableListToShow = this.toolNameList;
      var showIndex = tableListToShow.findIndex((el) => el === evt._state.ToolName);
      // close this dialog before going to the photo dialog
      await this.$root.$onephotodialog(tableListToShow, showIndex, {
        authObject: this.options.authObject,
      });
    },
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    MobileClass() {
      if (this.$vuetify.breakpoint.smAndDown) return "ma-0 pa-0";
      return undefined;
    },
    MaxWidth() {
      if (this.$vuetify.breakpoint.smAndDown) return undefined;
      return this.options.width;
    },
    AllTools() {
      //console.log("AT "+JSON.stringify(this.$store.getters["tools/getTools"]))
      return this.$store.getters["tools/getTools"];
    },
    RawTools() {
      return this.$store.getters["tools/getRawTools"];
    },
    ToolsToDisplay() {
      if (this.ToolViewList === undefined) return {};
      var AllToolsToUse = this.AllTools;
      if (Object.keys(this.InjectedTools).length !== 0) {
        AllToolsToUse = JSON.parse(JSON.stringify(this.InjectedTools)); // needed to avoid infinite loop...
      }
      return ProcessToolData.PopulateToolViewList(this.ToolViewList, AllToolsToUse);
    },
    canEdit() {
      if (
        this.options.authObject === undefined ||
        this.options.authObject.editEnable === undefined
      )
        return false;
      return this.options.authObject.editEnable;
    },
  },
};
</script>
