<template>
  <v-card
    color="primary"
    class="ma-auto"
    outlined
    :max-width="MaxWidth"
    :width="WidthTarget"
    min-width="350"
    :key="componentKey"
  >
    <!-- Header -->
    <v-card-title :class="getColorClass" @click="titleClick()">
      <v-btn icon v-if="canExpand" @click="leftIconClick()">
        <v-icon v-if="Tool._expanded" :color="getIconColor.expanded" class="mr-3"
          >mdi-collapse-all</v-icon
        >
        <v-icon v-else :color="getIconColor.collapsed" class="mr-3"
          >mdi-expand-all</v-icon
        >
      </v-btn>
      <span
        v-if="CardView.Header.canedit === 'text' && canEdit"
        :contenteditable="ifDerived(CardView.Header, false, true)"
        :class="ifDerived(CardView.Header, DerivedFontColor, '')"
        :sourceid="generateSource(CardView.Header)"
        @blur="textChangeEvent"
      >
        {{ getTextData(CardView.Header) }}
      </span>
      <span v-else @click="leftIconClick()">{{ getTextData(CardView.Header) }}</span>
      <span class="ml-2">{{ getGroupCountText() }}</span>
      <!-- Tool Status -->
      <template v-if="isShutdown">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red accent-1" class="mx-3" v-bind="attrs" v-on="on"
              >mdi-alert</v-icon
            >
          </template>
          <span>{{ shutdownText }}</span>
        </v-tooltip>
      </template>

      <template v-if="isProblem">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="amber accent-1" class="mx-3" v-bind="attrs" v-on="on"
              >mdi-help-circle</v-icon
            >
          </template>
          <span>{{ problemText }}</span>
        </v-tooltip>
      </template>

      <v-spacer></v-spacer>
      <!-- Editor Controls -->
      <v-btn icon v-if="!canEdit && AllowSuggestions">
        <v-icon :color="getIconColor.other">mdi-email-edit-outline</v-icon>
      </v-btn>

      <v-btn icon v-if="canEditPhoto">
        <v-icon :color="getIconColor.other" @click="showPhotoUpload()">mdi-camera</v-icon>
      </v-btn>
      <v-btn icon v-if="canEditCode && !somethingWasModified">
        <v-icon :color="getIconColor.other" @click="showCodeEditor()"
          >mdi-file-document-edit-outline</v-icon
        >
      </v-btn>
      <v-btn icon v-if="canEdit && somethingWasModified">
        <v-icon :color="getIconColor.other" @click="buttonUndo()">mdi-undo</v-icon>
      </v-btn>
      <v-btn icon v-if="canEdit && somethingWasModified">
        <v-icon :color="getIconColor.other" @click="buttonSave()"
          >mdi-cloud-upload-outline</v-icon
        >
      </v-btn>
      <!-- Card View toggles -->
      <v-btn icon v-if="hasMultipleViews">
        <v-icon :color="getIconColor.other" @click="rightIconClick()">{{
          NextViewIcon
        }}</v-icon>
      </v-btn>
      <v-btn icon v-if="AddCloseButton === true">
        <v-icon :color="getIconColor.other" @click="$emit('closeRequest')"
          >mdi-close</v-icon
        >
      </v-btn>
    </v-card-title>
    <!-- Card Image. Use placeholder to insert ImageIcon (or default to "unknown" Icon to indicate an image or icon is needed) -->
    <v-img
      v-if="
        ShowImageForCard !== false &&
        thumbnailSource !== null &&
        CardView.Image !== undefined
      "
      :src="thumbnailSource"
      :height="imageHeight"
      :gradient="imageGradient"
      class="grey darken-4"
      @click="toggleImage()"
      ><template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-icon :color="getColorClass" large>mdi-progress-download</v-icon>
        </v-row>
      </template></v-img
    >
    <v-img
      v-if="
        ShowImageForCard !== false &&
        thumbnailSource === null &&
        CardView.Image !== undefined
      "
      src="/images/empty.jpg"
      :height="imageHeight"
      :class="getIconData(Tool, 'background', 'grey darken-4')"
      :gradient="getIconData(Tool, 'gradient', imageGradient)"
      @click="toggleImage()"
    >
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-icon
          :color="getIconData(Tool, 'color', getColorClass)"
          :size="CardView.Image.height"
          >{{ getIconData(Tool, "name", "mdi-image-off-outline") }}</v-icon
        >
      </v-row>
    </v-img>
    <!-- BODY STARTS HERE -->
    <!-- Text Descriptor / Subheader One-Liner -->
    <v-list class="py-0" :color="getTheme('oneline')" v-if="CardView.Subheader">
      <v-subheader>
        <span
          v-if="CardView.Header.canedit === 'text' && canEdit"
          :contenteditable="!ifDerived(CardView.Subheader)"
          :class="ifDerived(CardView.Subheader, DerivedFontColor, EditableFontColor)"
          :sourceid="generateSource(CardView.Subheader)"
          @blur="textChangeEvent"
          >{{ getTextData(CardView.Subheader) }}</span
        >
        <span v-else contenteditable="false" class="cardtext--text">{{
          getTextData(CardView.Subheader)
        }}</span>
      </v-subheader>
      <v-divider />
    </v-list>

    <!-- Section Display -->
    <v-list
      class="py-0"
      :color="getTheme('card')"
      v-for="(section, iSection) in CardViewPrecalc"
      :key="iSection"
    >
      <!-- #### Feature: Buttons #### -->
      <v-list-item v-if="section.Buttons !== undefined">
        <v-list-item-content>
          <span
            v-if="canEdit"
            :contenteditable="!ifDerived(section.Name)"
            :class="ifDerived(section.Name, DerivedFontColor, EditableFontColor)"
            :sourceid="generateSource(section.Name)"
            @blur="textChangeEvent"
          >
            {{ section.Name._value }}</span
          >
          <span v-else contenteditable="false" class="cardtext--text">{{
            section.Name._value
          }}</span>
          <v-chip-group
            active-class="primary--text"
            v-model="ButtonSelection[section.Target._value]"
            :mandatory="section.Mandatory"
          >
            <v-chip
              v-for="(buttonentry, iButtonEntry) in getButtons(section)"
              :key="iButtonEntry"
              :value="getButtonKeyValue(section, iButtonEntry)"
              class="ma-2"
              outlined
              :active-class="SelectedButtonColor"
            >
              <v-icon left> {{ getTextData(buttonentry.Icon) }} </v-icon>
              {{ getTextData(buttonentry.Title) }}
            </v-chip>
          </v-chip-group>
        </v-list-item-content>
      </v-list-item>

      <template
        v-if="
          checkIfShowOnly(section.ShowIf, ButtonSelection) && section._entries.length > 0
        "
      >
        <!-- #### Section Content #### -->
        <v-list-group
          color="primary"
          v-model="showSections[iSection]"
          multiple
          v-if="section.Collapsible"
        >
          <template v-slot:activator>
            <v-list-item class="cardtext--text">{{ section.Name._value }}</v-list-item>
          </template>
          <v-divider />
        </v-list-group>
        <template v-if="!section.Collapsible || showSections[iSection]">
          <template v-for="(sectionentry, iSectionEntry) in section._entries">
            <v-subheader
              :key="iSectionEntry"
              v-if="sectionentry.header !== undefined"
              class="cardtext--text"
            >
              {{ sectionentry.header._value }}
            </v-subheader>
            <v-list-item
              :key="iSectionEntry"
              :to="getLinkForItem(textOrSource(sectionentry.Link))"
              :href="getHrefForItem(textOrSource(sectionentry.Link))"
              :target="ifHrefForItem(textOrSource(sectionentry.Link), '_blank')"
              v-if="
                checkIfShowOnly(sectionentry.ShowIf, ButtonSelection) &&
                (canEdit || isNotEmpty(sectionentry))
              "
            >
              <template v-if="hasImageData(sectionentry)">
                <v-list-item-avatar
                  :width="getImageSize(sectionentry, 0)"
                  :size="getImageSize(sectionentry, 1)"
                  :rounded="getBoxRounding(sectionentry, 'xl')"
                  @click="clickedImage(sectionentry)"
                >
                  <v-img
                    :src="getLinkForImage(getTextData(sectionentry.Image).Use)"
                  ></v-img>
                </v-list-item-avatar>
              </template>
              <template v-else>
                <v-list-item-avatar v-if="checkThatDefined(sectionentry.Icon)">
                  <v-icon color="cardicon">{{ sectionentry.Icon._value }}</v-icon>
                </v-list-item-avatar>
              </template>
              <v-list-item-content>
                <v-list-item-title
                  v-if="
                    checkThatDefined(sectionentry.Title) &&
                    canEdit &&
                    sectionentry.Title.canedit === 'text'
                  "
                  :contenteditable="!ifDerived(sectionentry.Title)"
                  :class="
                    ifDerived(sectionentry.Title, DerivedFontColor, EditableFontColor)
                  "
                  :sourceid="generateSource(sectionentry.Title)"
                  @blur="textChangeEvent"
                  >{{ sectionentry.Title._value }}</v-list-item-title
                >
                <v-list-item-title
                  v-if="
                    checkThatDefined(sectionentry.Title) &&
                    !(canEdit && sectionentry.Title.canedit === 'text')
                  "
                  contenteditable="false"
                  class="cardtext--text"
                  >{{ sectionentry.Title._value }}</v-list-item-title
                >

                <v-list-item-subtitle
                  v-for="(contents, iContents) in preprocessContents(
                    sectionentry.Contents
                  )"
                  :key="iContents"
                  class="text-wrap cardtext--text"
                >
                  {{ contents.name }}:
                  <span
                    v-if="contents.canedit === 'text' && canEdit"
                    :contenteditable="!ifDerived(contents)"
                    :class="ifDerived(contents, DerivedFontColor, EditableFontColor)"
                    :sourceid="generateSource(contents)"
                    @blur="textChangeEvent"
                    >{{ contents._contentTextData }}</span
                  >
                  <span v-else contenteditable="false" class="cardtext--text">{{
                    contents._contentTextData
                  }}</span>
                </v-list-item-subtitle>
                <template v-if="sectionentry.Text !== undefined">
                  <v-list-item-subtitle
                    class="text-wrap"
                    v-if="sectionentry.Text.canedit === 'text' && canEdit"
                    :contenteditable="!ifDerived(sectionentry.Text)"
                    :class="
                      ifDerived(sectionentry.Text, DerivedFontColor, EditableFontColor)
                    "
                    :sourceid="generateSource(sectionentry.Text)"
                    @blur="textChangeEvent"
                    >{{ sectionentry.Text._value }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle v-else class="text-wrap cardtext--text">{{
                    sectionentry.Text._value
                  }}</v-list-item-subtitle>
                </template>
                <!-- multi text is a list of text lines, otherwise identical to text -->
                <template v-if="sectionentry.MultiText !== undefined">
                  <v-list-item-subtitle
                    v-for="(listEntry, iList) in sectionentry.MultiText"
                    :key="iList"
                    class="text-wrap cardtext--text"
                  >
                    {{ listEntry.prefix }} {{ listEntry._value }}
                  </v-list-item-subtitle>
                </template>

                <v-list-item-subtitle
                  v-if="sectionentry.FixedText !== undefined"
                  class="text-wrap cardtext--text"
                  >{{ sectionentry.FixedText }}</v-list-item-subtitle
                >
                <template v-if="sectionentry.List !== undefined">
                  <v-list-item-subtitle
                    v-for="(listEntry, iList) in sectionentry.List._value"
                    :key="iList"
                    class="text-wrap cardtext--text"
                  >
                    {{ listEntry }}
                  </v-list-item-subtitle></template
                >
              </v-list-item-content></v-list-item
            ></template
          ></template
        >
        <v-divider />
      </template>
    </v-list>
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
var moment = require("moment");
import dataloader from "@/api/dataloader.js";
//import ViewStructures from "./ViewStructure.js";
import ProcessToolData from "@/support/processToolData.js";
import DataPrep from "@/support/dataPrep.js";

const _ = require("lodash");

export default {
  data: () => ({
    showSections: [],
    somethingWasModified: false,
    modifications: {},
    viewmode: "",
    componentKey: 0,
    DerivedFontColor: "blue-grey--text text--lighten-3",
    EditableFontColor: "indigo--text text--darken-2 blue-grey lighten-5",
    SelectedButtonColor: "deep-purple--text text--accent-4",
    ButtonSelection: {},
  }),

  props: {
    //selectionGroup: String,
    DefaultViewmode: String,
    //ViewList: Array,
    AllToolList: Array,
    RawToolList: Object,
    //    rawToolDetails: String,
    CtrToolToShow: Number,
    ForceViewSequence: {
      default() {
        return "";
      },
      type: String,
    },
    //editable: {
    auth: {
      default() {
        return { editEnable: false, permissions: [] };
      },
      type: Object,
    },
    initialState: {
      default() {
        return {};
      },
      type: Object,
    },
    FillWidth: {
      default() {
        return false;
      },
      type: Boolean,
    },
    AddCloseButton: {
      default() {
        return false;
      },
      type: Boolean,
    },
    AllowSuggestions: {
      default() {
        return false;
      },
      type: Boolean,
    },
  },

  watch: {
    // watch expansion/selection and emit as event
    // warning: cyclical watchers... must adjust on level up
    showSections: function (val) {
      console.log("showSections changed " + JSON.stringify(val) + " " + this.Tool.Name);
      this.$emit("stateChanged", { _state: { sections: val } });
    },
    initialState: function (state) {
      console.log("Card: State change " + JSON.stringify(state) + " . " + this.Tool.Name);
      if (state.sections !== undefined) {
        // only change if modified, this avoids infinite loop of change->event->change...
        const strSections = JSON.stringify(state.sections);
        if (JSON.stringify(this.showSections) !== strSections)
          this.showSections = JSON.parse(strSections);
      } else {
        this.showSections = [];
      }
      if (state.CardView !== undefined) {
        console.log("TCV - VM resetting card view " + JSON.stringify(state.CardView));
        this.viewmode = state.CardView;
      } else {
        console.log("TCV - VM default card view " + JSON.stringify(this.DefaultViewmode));
        this.viewmode = this.DefaultViewmode;
      }
      if (this.viewmode == "") this.viewmode = this.viewSequence[0].name;
      console.log("card view = " + JSON.stringify(this.viewmode) + ".");
    },
    auth: function () {
      // if auth changes, re-render because changes from edit to no-edit modes require contenteditable recalculation
      this.forceRerender();
    },
  },
  mounted() {
    console.log("MOUNT");
    const state = _.cloneDeep(this.initialState);
    if (state.sections !== undefined) {
      this.showSections = _.cloneDeep(state.sections);
    } else {
      this.showSections = [];
    }
  },

  computed: {
    ViewStructures() {
      return this.$store.getters.getViewStructure;
    },
    Styling() {
      return this.$store.getters["getStyling"];
    },
    isFullWidth() {
      if (this.Tool.FullWidth === true) return true;
      if (Array.isArray(this.Tool.FullWidth)) {
        return this.Tool.FullWidth.includes(this.Tool.Type);
      }
      return false;
    },
    WidthTarget() {
      if (this.FillWidth) undefined;
      if (this.isFullWidth) return undefined;
      if (this.$vuetify.breakpoint.smAndDown) return this.$vuetify.breakpoint.width;
      return 500;
    },
    MaxWidth() {
      if (this.FillWidth) return undefined;
      if (this.isFullWidth) return undefined;
      if (this.$vuetify.breakpoint.smAndDown) return undefined;
      return 500;
    },
    ToolStatus() {
      //console.log("get status");
      return this.$store.getters["tools/getToolStatus"];
    },
    canExpand() {
      if (this.CardView.PreventExpansion === true) return false;
      return this.Tool.Type === "Group" || this.Tool.Type === "Build";
    },
    hasMultipleViews() {
      return this.NextViewIcon != "";
    },
    imageHeight() {
      if (this.CardView.Image === undefined) return 0;
      return this.CardView.Image.height;
    },
    imageGradient() {
      const s = "to top, rgba(0,0,0,.25), rgba(255,255,255,.6)";
      //console.log("Img gradient " + JSON.stringify(this.CardView.Image));
      if (this.CardView.Image === undefined) return s;
      var gradientObj = this.CardView.Image.gradient;
      // is gradient a string? then return it
      // otherwise, it's an object and return the contents for the tooltype
      if (gradientObj === undefined) return s;
      if (typeof gradientObj === "string") return gradientObj;
      gradientObj = gradientObj[this.tooltype];
      if (gradientObj === undefined) return s;
      return gradientObj;
    },
    toolname() {
      return this.Tool.Name;
    },
    tooltype() {
      return this.Tool.Type;
    },
    canEdit() {
      return this.auth.editEnable && this.auth.permissions.includes("edit-tools");
    },
    canEditCode() {
      return this.auth.editEnable && this.auth.permissions.includes("edit-code");
    },
    canEditPhoto() {
      return this.auth.editEnable && this.auth.permissions.includes("edit-photos");
    },
    thumbnailSource() {
      if (this.CardView.Image !== undefined) {
        if (this.CardView.Image.source !== undefined) {
          if (this.Tool[this.CardView.Image.source] !== undefined) {
            return this.getLinkForImage(this.Tool[this.CardView.Image.source]);
          }
        }
      }
      if (this.Tool.ImageName !== undefined)
        return this.getLinkForImage(this.Tool.ImageName);
      return null;
    },
    checkTextClass() {
      var sClass = "text-wrap";
      if (this.testdataOriginal !== this.testdata) sClass = sClass + " red--text";
      return sClass;
    },
    isShutdown() {
      const tool = this.Tool;
      if (tool === undefined) return false;
      if (tool.shutdowns === undefined) return false;
      return tool.shutdowns > 0;
    },
    isProblem() {
      const tool = this.Tool;
      if (tool === undefined) return false;
      if (tool.problems === undefined) return false;
      if (tool.shutdowns !== undefined) if (tool.shutdowns > 0) return false;
      return tool.problems > 0;
    },
    problemText() {
      const tool = this.Tool;
      if (tool === undefined) return "Problem";
      if (this.ToolStatus[tool.Name] === undefined) return "Problem";
      const entry = this.ToolStatus[tool.Name];
      if (entry.problemDetails.length === 0) return "Undefined Problem";
      var s =
        "Problem " +
        moment(entry.problemDetails[entry.problemDetails.length - 1].reported).format(
          "MMM DD"
        ) +
        ": " +
        entry.problemDetails[entry.problemDetails.length - 1].definition;
      if (entry.problemDetails[entry.problemDetails.length - 1].definition === "")
        s = s + " Unspecified";

      return s;
    },

    shutdownText() {
      const tool = this.Tool;
      if (tool === undefined) return "Shutdown";
      if (this.ToolStatus[tool.Name] === undefined) return "Shutdown";
      const entry = this.ToolStatus[tool.Name];
      if (entry.shutdownDetails.length === 0) return "Undefined Shutdown";
      var s =
        "Shutdown " +
        moment(entry.shutdownDetails[entry.shutdownDetails.length - 1].reported).format(
          "MMM DD"
        ) +
        ": " +
        entry.shutdownDetails[entry.shutdownDetails.length - 1].definition;
      if (entry.shutdownDetails[entry.shutdownDetails.length - 1].definition === "")
        s = s + " Unspecified";

      return s;
    },

    viewSequence() {
      // get the view sequence for the tool type, or use the default sequence if none set
      var result = this.ViewStructures.toolViewSequence[this.tooltype];
      if (this.ForceViewSequence != "") {
        result = this.ViewStructures.toolViewSequence[this.ForceViewSequence];
      }
      if (result === undefined) return this.ViewStructures.toolViewSequence["Default"];
      return result;
    },

    viewStructure() {
      // get the view structure that should be used and return the instructions
      var s = this.DefaultViewmode;
      console.log("View mode " + s);
      console.log(JSON.stringify(this.ViewStructures.toolViews));
      if (this.viewmode !== "") s = this.viewmode;
      if (this.ViewStructures.toolViews[s] === undefined) s = "Default";
      var result = this.ViewStructures.toolViews[s];
      console.log(JSON.stringify(result));
      return result;
    },
    CardViewPrecalc() {
      var sections = this.CardView.Sections;
      //console.log("!!! Precalc " + JSON.stringify(sections));
      // pre-process the sections, to make it easier to remove unused sections
      for (var section of sections) {
        console.log("section: " + JSON.stringify(section.Name));
        section.Collapsible = this.resolveValue(section.Collapsible);
        section.Name = this.resolveValue(section.Name);
        section.Target = this.resolveValue(section.Target);
        section._entries = [];
        // skip this section, if viewtags not matching
        if (!this.matchViewTags(section.ViewTags)) continue;
        if (section.Entries !== undefined) {
          for (var entry of section.Entries) {
            if (!this.matchViewTags(entry.ViewTags)) continue;
            var k;
            //console.log(JSON.stringify(entry));
            entry.Text = this.resolveValue(entry.Text);
            entry.header = this.resolveValue(entry.header);
            entry.Title = this.resolveValue(entry.Title);
            entry.Icon = this.resolveValue(entry.Icon);
            if (entry.MultiText !== undefined) {
              for (k = 0; k < entry.MultiText.length; k++) {
                entry.MultiText[k] = this.resolveValue(entry.MultiText[k]);
              }
            }
            if (entry.Contents !== undefined) {
              for (k = 0; k < entry.Contents.length; k++) {
                if (!this.matchViewTags(entry.Contents[k].ViewTags)) {
                  entry.Contents[k] = null;
                } else {
                  entry.Contents[k] = this.resolveValue(entry.Contents[k]);
                }
              }
            }
            entry.List = this.resolveValue(entry.List);
            // check if text, list, contents are all empty
            var showThis = this.canEdit;
            var testArray = [entry.MultiText, entry.Text].concat(
              entry.List,
              entry.Contents
            );

            //            console.log("CHECK: " + JSON.stringify(testArray));
            for (var checkThis of testArray) {
              if (checkThis !== undefined && checkThis !== null) {
                if (checkThis._value !== "") showThis = true;
              }
            }
            if (showThis) section._entries.push(entry);
          }
        }
        if (section.FromList !== undefined) {
          // generate list and add
          //console.log("FL: " + JSON.stringify(sections.FromList));
          section._entries = section._entries.concat(
            this.getTextData(section.FromList, this.Tool)
          );
        }
        //console.log("entries: " + JSON.stringify(section._entries));
      }
      console.log("result: " + JSON.stringify(sections));
      return sections;
    },

    CardView() {
      // listen to the counter to trigger refresh when saving or updating
      console.log("Card View Change " + this.Tool.Name);
      //this.somethingWasModified = false --> ERROR
      if (this.viewStructure === undefined) return {};
      var result = _.cloneDeep(this.viewStructure);
      //console.log(JSON.stringify(result.Build));
      // use any previously defined sections, except those that are built
      if (result.Sections === undefined) result.Sections = [];
      else
        result.Sections = result.Sections.filter((el) => {
          if (!this.isNotExcluded(el.SkipFor, this.Tool.Type)) return false;
          return el._built !== true;
        });

      // process build of the sections
      if (result.Build !== undefined) {
        console.log("BUILD START");
        // go through the source list, and add sections for each entry, differentiated by type
        var dataSource = result.Build.source;
        var toolBuild = this.getTextData(dataSource);
        for (var k = 0; k < toolBuild.length; k++) {
          const entry = toolBuild[k];
          var instructions = result.Build[entry.Type];
          var lineInstructions = null;
          var lineEntry = null;

          var refSource = null;
          var m = 0;
          //console.log("i=" + JSON.stringify(instructions));
          if (instructions !== undefined) {
            // create a new section
            var newSection = {
              Collapsible: this.getProperty(instructions, "Collapsible", false),
              _built: true,
            };
            // collapsible can be fixed by the view mode, or flexible depending on the object to display
            // if there is a source link, then it's flexible -- and adjust for build
            if (typeof newSection.Collapsible === "object") {
              if (newSection.Collapsible.source !== undefined) {
                newSection.Collapsible.source =
                  dataSource + "." + k + "." + newSection.Collapsible.source;
              }
            }

            if (instructions.ShowIf !== undefined) {
              newSection.ShowIf = newSection.Target = {
                source: dataSource + "." + k + ".ShowIf",
              };
            }

            if (instructions.Name !== undefined) {
              if (instructions.Name.source !== undefined) {
                newSection.Name = {
                  source: dataSource + "." + k + "." + instructions.Name.source,
                };
              }
            }
            if (instructions.Buttons !== undefined) {
              refSource = dataSource + "." + k + ".Build.";
              newSection.Target = { source: dataSource + "." + k + ".Target" };
              lineInstructions = instructions.Buttons;
              newSection.Buttons = [];
              var keyList = [];
              for (m = 0; m < entry.Build.length; m++) {
                lineEntry = _.cloneDeep(lineInstructions);
                this.modifySource(lineEntry, refSource + m + ".");
                newSection.Buttons.push(lineEntry);
                keyList.push(refSource + m + ".Value");
              }
              newSection.Mandatory = entry.Mandatory;
              newSection.InitialSelection = entry.InitialSelection;
              newSection.keyList = keyList;
            }
            if (instructions.Build !== undefined) {
              // inner build for the line entries of the section
              refSource = dataSource + "." + k + ".Build.";
              lineInstructions = instructions.Build;
              newSection.Entries = [];
              for (m = 0; m < entry.Build.length; m++) {
                lineEntry = _.cloneDeep(lineInstructions);
                this.modifySource(lineEntry, refSource + m + ".");
                newSection.Entries.push(lineEntry);
              }
            }

            //console.log("entry " + JSON.stringify(entry));
            //console.log(JSON.stringify(instructions));
            //console.log(JSON.stringify(dataSource + "." + k));
            //console.log(JSON.stringify(this.getTextData(dataSource + "." + k)));
            // don't show section if there are no entries
            result.Sections.push(newSection);
          }
        }
        console.log("BUILD END");
      }
      // remove any empty sections
      result.Sections = result.Sections.filter((el) => {
        if (el.Entries === undefined) {
          return el.Buttons !== undefined;
        }
        return el.Entries.length > 0;
      });
      //console.log("A " + JSON.stringify(result.Sections));
      return result;
    },
    ShowImageForCard() {
      //if (this.Tool.Type !== "Tool" && this.Tool._expanded) return false;
      if (this.isFullWidth) return false;
      return this.CardView.Image;
    },
    NextViewIcon() {
      // retrieve the icon if there are multiple views. if not, return blank
      if (this.viewSequence.length <= 1) return "";
      var viewModeName = this.viewmode;
      if (viewModeName === "") viewModeName = this.DefaultViewmode;
      var iconIndex = this.viewSequence.findIndex((el) => el.name === viewModeName);
      // if not found, default to 1st entry
      if (iconIndex === -1) {
        iconIndex = 0;
      } else {
        iconIndex = iconIndex + 1;
      }
      if (iconIndex >= this.viewSequence.length) iconIndex = 0;
      return this.viewSequence[iconIndex].icon;
    },
    Tool() {
      //console.log(this.CtrToolToShow);
      //console.log(JSON.stringify(this.AllToolList))
      for (const [, tool] of Object.entries(this.AllToolList)) {
        //for (const tool of this.AllToolList) {
        if (tool._ctr == this.CtrToolToShow) {
          return tool;
        }
      }
      console.log("NOT FOUND");
      return {};
    },

    getColorClass() {
      if (this.Tool !== undefined) {
        const entryType = this.Tool.Type;
        if (entryType !== "Tool") {
          if (this.isFullWidth) {
            return this.StylingByName("cardview.widegroup.header");
          } else {
            return this.StylingByName("cardview.group.header");
          }
        }
      }
      return this.StylingByName("cardview.tool.header");
    },
    getIconColor() {
      if (this.Tool !== undefined) {
        const entryType = this.Tool.Type;
        if (entryType !== "Tool") {
          if (this.isFullWidth) {
            return this.StylingByName("cardview.widegroup.iconcolor", {});
          } else {
            return this.StylingByName("cardview.group.iconcolor", {});
          }
        }
      }
      return this.StylingByName("cardview.tool.iconcolor", {});
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  methods: {
    getTheme(fieldname) {
      var entry = this.$vuetify.theme.themes[this.theme];
      if (entry == undefined) return undefined;
      return entry[fieldname];
    },
    StylingByName(source, defaultvalue = undefined) {
      var entry = this.Styling;
      for (const word of source.split(".")) {
        if (entry[word] === undefined) return defaultvalue;
        entry = entry[word];
      }
      return entry;
    },

    matchViewTags(taglist) {
      // check if viewtags match. if none defined in the tool, the always good.
      // taglist is what the view structure allows/prohibits.
      // go through the tool's list and see if any + matches exist
      if (taglist === undefined) return true;
      var useTags = this.Tool.ViewTags;
      if (useTags === undefined) useTags = ["default"];
      var showThis = false;
      for (const entry of useTags) {
        if (taglist.includes(entry)) showThis = true;
        if (taglist.includes("-" + entry)) return false;
      }
      return showThis;
    },
    preprocessContents(contents) {
      if (contents === undefined) return undefined;
      var result = [];
      for (var entry of contents) {
        // undefined entries are due to ViewTag filtering
        if (entry === null) continue;
        var newEntry = entry;
        newEntry._contentTextData = this.getTextData(entry);
        if (newEntry._contentTextData !== undefined) result.push(newEntry);
      }
      console.log(JSON.stringify(result));
      return result;
    },
    isNotEmpty(sectionentry) {
      //console.log("CHECK " + JSON.stringify(sectionentry));
      // if there is a title, but no subtitle or other content, then hide the entry
      //if (!this.checkThatDefined(sectionentry.Title)) return true;
      if (sectionentry.Contents !== undefined) return true;
      if (this.checkThatDefined(sectionentry.Text)) {
        //return this.getTextData(sectionentry.Text) !== "";
        return true;
      }
      if (sectionentry.MultiText !== undefined) return true;
      if (sectionentry.FixedText !== undefined) return true;
      if (this.checkThatDefined(sectionentry.List)) return true;
      //console.log("SKIPPING");
      //if (this.getTextData(sectionentry.List)!==undefined) return true
      //if (this.getTextData(sectionentry.Text)!==undefined) return true
      return false;
    },
    hasImageData(sectionentry) {
      if (sectionentry === undefined) return false;
      if (sectionentry.Image === undefined) return false;
      const imageObj = this.getTextData(sectionentry.Image, false);
      if (imageObj === undefined) return false;
      return true;
    },
    getLinkForImage(imageName) {
      if (!imageName.endsWith(".jpg")) imageName = imageName + ".jpg";
      return "/images/" + imageName;
    },
    getIconData(tool, fieldname, defaultvalue) {
      if (tool === undefined || tool.ImageIcon === undefined) return defaultvalue;
      if (tool.ImageIcon[fieldname] === undefined) return defaultvalue;
      return tool.ImageIcon[fieldname];
    },
    getImageSize(sectionentry, n) {
      const imageObj = this.getTextData(sectionentry.Image);
      if (imageObj === undefined) return undefined;
      if (imageObj.Size === undefined) return undefined;
      return imageObj.Size[n];
    },
    getBoxRounding(sectionentry, defaultValue) {
      if (sectionentry.Image.isRound) return false;
      return defaultValue;
    },
    clickedImage(sectionentry) {
      const imageObj = this.getTextData(sectionentry.Image);
      if (imageObj.Action !== true) return false;
      console.log("IMAGE ACTION");
    },

    getLinkForItem(link) {
      if (this.canEdit) return ""; // links are not in effect when editing, so one can click on text fields without launching the link
      if (link === undefined) return "";
      if (link.startsWith("http")) return "";
      return link;
    },
    getHrefForItem(link) {
      if (this.canEdit) return ""; // links are not in effect when editing, so one can click on text fields without launching the link
      if (link === undefined) return "";
      if (link.startsWith("http")) return link;
      return "";
    },
    ifHrefForItem(link, target) {
      if (this.canEdit) return ""; // links are not in effect when editing, so one can click on text fields without launching the link
      if (link === undefined) return "";
      if (link.startsWith("http")) return target;
      return "";
    },

    forceRerender() {
      this.componentKey++;
      //this.$forceUpdate()
    },
    textChangeEvent(evt) {
      console.log(JSON.stringify(evt.target));
      console.log(JSON.stringify(evt.target.innerText));
      console.log(JSON.stringify(evt.target.getAttribute("sourceid")));
      const newText = evt.target.innerText;
      const dataSource = evt.target.getAttribute("sourceid");
      console.log("CHANGE");
      //console.log(newText);
      //console.log(dataSource);
      // check if text changed
      if (this.getTextData(dataSource) !== newText) {
        console.log("CHANGED");
        console.log(">" + this.getTextData(dataSource) + "<");
        console.log(">" + newText + "<");
        // store the changes
        this.modifications[dataSource] = newText;
        this.somethingWasModified = true;
      }
    },
    getButtons(section) {
      // return the button entries
      var result = [];
      if (section.Buttons !== undefined) result = section.Buttons;
      return result;
    },
    getButtonKeyValue(section, ctr) {
      return this.getTextData(section.keyList[ctr]);
    },
    getProperty(obj, prop, defaultValue) {
      if (obj === undefined) return defaultValue;
      if (obj[prop] === undefined) return defaultValue;
      return _.cloneDeep(obj[prop]);
    },
    getGroupCountText() {
      var tooldata = this.Tool;
      if (
        (tooldata.Type === "Group" || tooldata.Type === "Build") &&
        tooldata._expanded !== true
      ) {
        if (this.canExpand) {
          return "(" + ProcessToolData.getToolCount(tooldata) + ")";
        }
      }
      return "";
    },
    generateSource(field) {
      //console.log("F1 " + JSON.stringify(field));
      return field.source;
    },
    modifySource(obj, prefix) {
      // add a prefix to all source keys
      for (const [, value] of Object.entries(obj)) {
        // if value is an array, iterate through contents
        if (Array.isArray(value)) {
          this.modifySource(value, prefix);
        }
        if (value.source !== undefined) {
          value.source = prefix + value.source;
        }
      }
    },
    ifDerived(field, ifTrue = true, ifFalse = false) {
      //console.log("F " + JSON.stringify(field));
      if (field === undefined) return ifFalse;
      if (this.Tool._derived_data === undefined) return ifFalse;
      if (this.Tool._derived_data.includes(field.source)) return ifTrue;
      return ifFalse;
    },
    isNotExcluded(SkipFor, ToolType) {
      if (SkipFor === undefined) return true;
      if (SkipFor.includes(ToolType)) return false;
      return true;
    },

    checkThatDefined(field, skipEmptyString = true) {
      if (field === undefined) return false;
      if (typeof field !== "object") return true;
      if (field._value !== undefined) {
        if (skipEmptyString && field._value === "") return false;
        return true;
      }
      if (this.getTextData(field, false) === undefined) return false;
      return true;
    },
    textOrSource(field) {
      // either return field, or call getTextData if a source link exists
      if (typeof field !== "object") return field;
      return this.getTextData(field, false);
    },
    getTextData(field, undefAlert = false) {
      console.log(field);
      try {
        return DataPrep.getTextDataForTool(field, this.Tool, undefAlert);
      } catch (ex) {
        return "Error for " + field;
      }
    },

    resolveValue(entry) {
      // check if entry is an object with a source. in that case, add _value to it
      // if not an object, return a {_value} object
      if (entry === undefined) return undefined;      
      if (entry === null) return undefined;
      if (typeof entry === "object") {
        entry._value = DataPrep.getTextDataForTool(entry, this.Tool, false);
        if (entry._value === undefined) entry._value = "";
        return entry;
      } else {
        if (entry === false) return entry;
        return { _value: entry };
      }
    },

    checkIfShowOnly(sourceField, buttonData) {
      if (sourceField === undefined) return true;
      const instructions = DataPrep.getTextDataForTool(sourceField, this.Tool, false);
      if (instructions === undefined) return true;
      const targetdata = buttonData[instructions.Target];
      if (targetdata === undefined) return true;
      var doesContain = instructions.Contains.includes(targetdata);
      if (instructions.invert) doesContain = !doesContain;
      return doesContain;
    },

    buttonUndo() {
      console.log("UNDO");
      this.modifications = {};
      this.somethingWasModified = false;
      this.forceRerender();
    },
    buttonSave() {
      console.log("SAVE");
      this.modifications._id = this.Tool._id;
      dataloader.sendToolUpdateData(this.modifications);
      this.$store.commit("tools/updateSingleTool", this.modifications);
      console.log("sending event");
      this.$emit("saveModification", this.modifications);
      // also refresh this card

      this.modifications = {};
      this.somethingWasModified = false;
      // update store
      //this.forceRerender();  --> don't because it loads the old data
    },
    async showPhotoUpload() {
      console.log("photo upload");
      // emit event to open the modal photo editor
      this.$emit("modalPhotoView", {
        _state: {
          ToolName: this.toolname,
        },
      });
    },
    async showCodeEditor() {
      console.log("editor");
      var aMessage = [];
      aMessage.push({
        textbr: "Code editor the " + this.Tool.Type + ": " + this.Tool.Name,
        textclass: "font-weight-bold",
      });
      aMessage.push({ textbr: " " });
      aMessage.push({
        textbr:
          "Edit the entire object contents in JSON here, in raw format (before processing inheritance, derivations or build). Warning: Incorrect edits may result in bad rendering or other problems. Only use when you know what you're doing!",
        textclass: "font-weight-light",
      });
      aMessage.push({ textbr: " " });
      aMessage.push({ divider: true });
      aMessage.push({ json: "ok" });

      //const thisTool = this.$store.getters["tools/getRawTool"](this.Tool.Name);

      await this.$root.$jsoneditordialog(
        "Editing: " + this.Tool.Name,
        this.RawToolList[this.Tool.Name],
        aMessage,
        {
          color: "secondary",
          reject: "Cancel",
          accept: "Save & Upload",
        }
      );
    },

    titleClick() {
      if (this.canExpand && this.hasMultipleViews) return;
      if (this.canExpand) {
        this.toggleExpandGroup();
        return;
      }
      if (this.hasMultipleViews) {
        this.toggleCardView();
        return;
      }
      this.toggleImage();
    },
    leftIconClick() {
      if (this.canExpand && this.hasMultipleViews) this.toggleExpandGroup();
    },
    rightIconClick() {
      if (this.canExpand && this.hasMultipleViews) this.toggleCardView();
    },

    toggleExpandGroup() {
      if (this.CardView.PreventExpansion === true) return;
      if (this.Tool.Type === "Group" || this.Tool.Type === "Build")
        this.$emit("toggleExpand", this.Tool._ctr);
    },
    toggleImage() {
      if (this.CardView.PreventExpansion === true) this.toggleCardView();
      // an image expands the group, or toggles the card view
      if (this.Tool.Type === "Group" || this.Tool.Type === "Build")
        this.toggleExpandGroup();
      else this.toggleCardView();
    },
    toggleCardView() {
      // move on to the next view in the toolViewSequence
      // find current spot, increment and set as new view
      // also send an event to update
      if (this.viewSequence.length == 1) {
        // if no sequence, then emit event to open the modal, or if expandable group then expand
        if (this.viewSequence[0].useModalTarget !== undefined) {
          this.$emit("modalToolView", {
            _state: {
              CardView: this.viewSequence[0].useModalTarget,
              ToolName: this.toolname,
            },
          });
        } else {
          this.toggleExpandGroup();
        }
        return;
      }
      console.log("TCV: this.vm: " + JSON.stringify(this.viewmode));
      var viewModeName = this.viewmode;
      if (viewModeName === "") {
        viewModeName = this.DefaultViewmode;
      }
      console.log("TCV: Current view mode: " + viewModeName);
      console.log("VS: " + JSON.stringify(this.viewSequence));
      var viewIndex = this.viewSequence.findIndex((el) => el.name === viewModeName);
      if (viewIndex === -1) viewIndex = 0;
      else viewIndex = viewIndex + 1;
      if (viewIndex >= this.viewSequence.length) viewIndex = 0;
      this.viewmode = this.viewSequence[viewIndex].name;
      console.log("TCV request view change to " + JSON.stringify(this.viewmode));
      this.$emit("stateChanged", { _state: { CardView: this.viewmode } });
    },
  },
};
</script>
